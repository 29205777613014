import React, { ReactNode, useEffect, useState } from "react";
import { AppContext } from "./appContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../auth/authContext";

const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<{ showAuthPopup: boolean }>({
    showAuthPopup: false,
  });
  const { token } = useAuth();
  const navTo = useNavigate();
  const location = useLocation();

  useEffect(() => {
    //TODO: support to be expanded to work beyond home page only
    if (!location || !isHomeUrl()) return;
    const url = getReturnUrl();
    if (url) {
      navTo(url, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const isHomeUrl = () => {
    const { pathname } = location;
    return pathname === "/" || pathname.includes("home");
  };

  const getReturnUrl = (): string | undefined => {
    const { search } = location;
    if (search) {
      const params = new URLSearchParams(search);
      return params.get("returnUrl") ?? undefined;
    }
    return undefined;
  };

  const navigate = (props: { url: string; externalLink?: string }) => {
    const { url, externalLink } = props;
    if (!token) {
      setState({ showAuthPopup: true });
      return;
    }
    setState({ showAuthPopup: false });
    if (externalLink) {
      window.open(externalLink, "_blank");
    } else {
      navTo(url);
    }
  };

  return (
    <AppContext.Provider
      value={{
        navigate,
        getReturnUrl,
        setAuthPopup: (showAuthPopup) => setState({ showAuthPopup }),
        showAuthPopup: state.showAuthPopup,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
