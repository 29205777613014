import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Button from "../../components/button";
import GridCard from "../../components/gridCard";
import MainHeadlineCard from "../../components/mainHeadlineCard";
import SectionTitle from "../../components/sectionTitle";
import useHome from "./util";

const sectionVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const transition = {
  duration: 0.8,
  type: "spring",
  stiffness: 100,
};

const HomePage = () => {
  const {
    news,
    documentaries: remoteDocumentaries,
    mainDocumentary,
    mainStory,
    podcasts,
    mainPodcast,
    handleClick,
  } = useHome();

  const [showTopStory, setShowTopStory] = useState(false);

  useEffect(() => {
    const heroHeight = (90 * window.innerHeight) / 100; // Hero section height (90vh)

    const handleScroll = () => {
      if (window.scrollY > heroHeight) {
        setShowTopStory(true);
      } else {
        setShowTopStory(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {/* Top Story Strip */}
      {showTopStory && mainStory && (
        <motion.div
          className="fixed top-25 left-0 right-0 bg-primaryColor text-white flex items-center px-4 py-2 shadow-lg z-49"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <img
            src={mainStory.image}
            alt={mainStory.title}
            className="w-12 h-12 object-cover rounded mr-4"
          />
          <Link to={``} onClick={handleClick} className="flex-grow">
            <div className="w-full">
              <h2 className="text-sm font-bold hover:underline">TOP STORY</h2>
              <h4 className="w-full max-w-[calc(100%-3rem)] text-sm font-medium hover:underline truncate overflow-hidden text-ellipsis whitespace-nowrap">
                {mainStory.title}
              </h4>
            </div>
          </Link>
          <button
            onClick={() => setShowTopStory(false)}
            className="text-white hover:text-gray-400"
          >
            ✕
          </button>
        </motion.div>
      )}

      {/* Hero Section */}
      <div
        className="relative h-[90vh] bg-fixed bg-center bg-cover"
        style={{ backgroundImage: `url(${mainStory?.image})` }}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center bg-black bg-opacity-60 text-white px-5 sm:px-20">
          <motion.h1
            className="text-3xl md:text-5xl"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            {mainStory?.title}
          </motion.h1>
          <motion.p
            className="text-lg md:text-2xl mt-4"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.7 }}
          >
            {mainStory?.synopsis}
          </motion.p>
          <Link
            to={``}
            onClick={handleClick}
            className="text-white inline-flex items-center md:mt-20 lg:mt-20 bg-black bg-opacity-50 rounded-lg px-4 py-2"
          >
            <motion.span
              className="text-gray-200 hover:text-primaryColor transition-colors duration-300 text-lg"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, delay: 1 }}
            >
              Read More
            </motion.span>
            <svg
              className="w-4 h-4 ml-2 transition-transform transform hover:translate-x-1"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>

      {/* Remaining Sections */}
      {/* ... (as in your original code) */}

      {/* Top News Section */}
      {news && (
        <motion.section
          className="text-gray-600 body-font  mx-auto"
          variants={sectionVariants}
          initial="hidden"
          animate="visible"
          transition={transition}
        >
          <div className="container px-5 pt-12 pb-12 mx-auto">
            <SectionTitle text={"Top News"} />
            <div className="flex flex-wrap -m-4">
              {news?.map((section) => (
                <GridCard key={section.id} {...section} />
              ))}
            </div>
            <div className="pt-10 flex justify-center">
              <Link to="/news">
                <Button text="View more news" onClick={() => {}} />
              </Link>
            </div>
          </div>
        </motion.section>
      )}

      {/* Documentaries Section */}
      {remoteDocumentaries && (
        <motion.section
          className="text-gray-600 body-font mx-auto"
          variants={sectionVariants}
          initial="hidden"
          animate="visible"
          transition={transition}
        >
          <div className="container px-5 pt-12 pb-12 mx-auto">
            <SectionTitle text={"Documentaries"} />
            <MainHeadlineCard {...mainDocumentary} />
            <div className="mb-8"></div>
            <div className="flex flex-wrap -m-4">
              {remoteDocumentaries.map((section) => (
                <GridCard key={section.id} {...section} />
              ))}
            </div>
            <div className="pt-10 flex justify-center">
              <Link to="/documentaries">
                <Button text="View more documentaries" onClick={() => {}} />
              </Link>
            </div>
          </div>
        </motion.section>
      )}

      {/* Podcasts Section */}
      {podcasts && (
        <motion.section
          className="text-gray-600 body-font mx-auto"
          variants={sectionVariants}
          initial="hidden"
          animate="visible"
          transition={transition}
        >
          <div className="container px-5 pt-12 pb-12 mx-auto">
            <SectionTitle text={"Podcasts"} />
            <MainHeadlineCard {...mainPodcast} />
            <div className="mb-8"></div>
            <div className="flex flex-wrap -m-4">
              {podcasts.map((section) => (
                <GridCard key={section.id} {...section} />
              ))}
            </div>
            <div className="pt-10 flex justify-center">
              <Link to="/podcasts">
                <Button text="View more podcasts" onClick={() => {}} />
              </Link>
            </div>
          </div>
        </motion.section>
      )}
    </div>
  );
};

export default HomePage;
