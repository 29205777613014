import React from "react";
import ErrorMessageProps from "./interface";

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  if (!message) return null;

  return <div className="mb-4 text-red-500">{message}</div>;
};

export default ErrorMessage;
