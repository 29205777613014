import { useState } from "react";
import { AxiosResponse } from "axios";
import axiosInstance from "../helpers/axiosInterceptors";

type AbpResponse<T> = {
  result?: T;
  success: boolean;
  error?: {
    message: string;
    details?: string;
  };
  targetUrl?: string;
};

type ApiResponse<T> = {
  data: T | null;
  error: string | null;
  loading: boolean;
  post: (body: any) => Promise<void>;
};

function usePost<T>(url: string): ApiResponse<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const post = async (body: any) => {
    setLoading(true);
    setError(null);
    setData(null);

    try {
      const response: AxiosResponse<AbpResponse<T>> = await axiosInstance.post(
        url,
        body
      );

      if (response.data.success) {
        setData(response.data.result || null);
      } else if (response.data.error) {
        setError(response.data.error.message);
      } else {
        setError("Unknown error occurred");
      }
    } catch (error: any) {
      setError(error.message || "Network error occurred");
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, post };
}

export default usePost;
