import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ErrorMessage from "../../components/errorMessage";
import useSignIn from "./utils";
import newspaperImage from "../../assets/images/newspaper.jpg";

const SignInPage = () => {
  const { error, form, signIn, setForm } = useSignIn();

  return (
    <div className="flex items-center justify-center min-h-screen">
      {/* Container for the Sign In Form and Additional Section */}
      <div className="relative w-full max-w-screen-lg flex flex-col lg:flex-row">
        {/* Sign In Form */}
        <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg z-10">
          <motion.h2
            className="text-3xl font-bold text-center text-gray-800"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Sign In
          </motion.h2>
          <form onSubmit={signIn} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email *
              </label>
              <input
                type="text"
                id="email"
                className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={form.userNameOrEmailAddress}
                onChange={(e) =>
                  setForm({ ...form, userNameOrEmailAddress: e.target.value })
                }
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password *
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={form.password}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
                required
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm">
                <Link
                  to={`/register`}
                  className="text-indigo-600 hover:text-indigo-700 transition duration-300"
                >
                  Register Account
                </Link>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-primaryColor rounded-md shadow-sm hover:bg-accentColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
              >
                Sign In
              </button>
            </div>
            <div className="flex items-center justify-center">
              <ErrorMessage message={error} />
            </div>
          </form>
        </div>

        {/* Additional Benefits Section */}
        <motion.div
          className="hidden lg:block w-1/2 h-full bg-gray-100 p-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col items-center justify-center h-full space-y-4">
            <img
              src={newspaperImage}
              alt="Benefits"
              className="w-3/4 h-auto rounded-lg shadow-lg"
            />
            <motion.h3
              className="text-xl font-semibold text-gray-800"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              Why Sign In?
            </motion.h3>
            <motion.p
              className="text-gray-600 text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              Sign in to access personalized content, save your preferences, and
              get the most out of our platform. Enjoy a tailored experience with
              exclusive features just for you.
            </motion.p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default SignInPage;
