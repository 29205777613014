import { useEffect, useState } from "react";
import ICardComponent from "../../../interfaces/cardComponent";
import INews from "../../../interfaces/news";
import useGet from "../../../network/useGet";
import convertToGridCardItem from "../../../utils/convertCardComponentToNewsBulletin";

const useDocumentaryDetails = (id: string) => {
  const { data, error, loading } = useGet<INews>(
    `/api/services/app/Documentary/Get?Id=${id}`
  );
  const [cardItem, setCardItem] = useState<ICardComponent | undefined>();
  useEffect(() => {
    if (data) {
      setCardItem(convertToGridCardItem(data));
    }

    if (error) {
      console.log(error);
    }
  }, [data, error]);

  return { data, error, cardItem, loading };
};

export default useDocumentaryDetails;
