import React from "react";
import { useAuth } from "../../providers/auth/authContext";
import Button from "../../components/button";
import RegisterUserProps from "../register/interface";
import useProfile from "./util";
import { FaUser, FaPhone, FaEnvelope, FaPencilAlt } from "react-icons/fa";
import { AccentColor, PrimaryColor } from "../../config/colors";
import LoaderContainer from "../../components/loader";

const ProfilePage = () => {
  const { profile: userOb } = useProfile("");

  const handleUpdate = () => {
    // Handle the update action
    console.log("Update button clicked");
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="container mx-auto bg-white shadow-lg rounded-md max-w-2xl w-full">
        <div className="relative flex flex-col items-center py-8">
          <img
            src={
              "https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352010-stock-illustration-default-placeholder-man-and-woman.jpg"
            }
            alt="User"
            className="w-32 h-32 rounded-full border border-gray-300 shadow-md"
          />
          {/**
          * <FaPencilAlt
            className="absolute bottom-2 right-2 bg-white rounded-full p-1 border border-gray-300 cursor-pointer"
            size={25}
            color={PrimaryColor}
          />
          * 
          */}
        </div>
        <div className="px-6 py-4">
          <div className="border-b border-gray-200 py-4">
            <div className="flex items-center space-x-4">
              <FaUser size={20} color={"#bcbcbc"} />
              <div>
                <div className="text-sm font-semibold text-gray-700">Name:</div>
                <div className="text-gray-600">{userOb?.firstName}</div>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-200 py-4">
            <div className="flex items-center space-x-4">
              <FaUser size={20} color={"#bcbcbc"} />
              <div>
                <div className="text-sm font-semibold text-gray-700">
                  Surname:
                </div>
                <div className="text-gray-600">{userOb?.lastName}</div>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-200 py-4">
            <div className="flex items-center space-x-4">
              <FaPhone size={20} color={"#bcbcbc"} />
              <div>
                <div className="text-sm font-semibold text-gray-700">
                  Mobile Number:
                </div>
                <div className="text-gray-600">{userOb?.mobileNumber1}</div>
              </div>
            </div>
          </div>
          <div className="py-4">
            <div className="flex items-center space-x-4">
              <FaEnvelope size={20} color={"#bcbcbc"} />
              <div>
                <div className="text-sm font-semibold text-gray-700">
                  Email:
                </div>
                <div className="text-gray-600">{userOb?.emailAddress1}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
