import axios, { AxiosResponse, AxiosError } from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "https://zarleaksapi.technobrainent.co.za/",
  timeout: 10000,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config: any): any => {
    // Ensure config.headers is initialized
    if (!config.headers) {
      config.headers = {};
    }

    // Modify request config before sending the request
    const token = localStorage.getItem("token");
    if (token) {
      (config.headers as any).Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    // Handle successful response
    return response;
  },
  (error: AxiosError): Promise<AxiosError> => {
    // Handle response error
    if (error.response) {
      const { status, data } = error.response;

      // Check for unauthorized status
      if (status === 401) {
        // Optional: Handle unauthorized logic, e.g., redirect to login
        alert("Session expired. Please log in again.");
        localStorage.removeItem("token"); // Clear token
        localStorage.removeItem("user");
        window.location.href = "/login"; // Redirect to login page
      }

      // Log other errors
      console.error("Response error", data);
    } else if (error.request) {
      // Request was made but no response was received
      console.error("No response received", error.request);
    } else {
      // Something happened in setting up the request
      console.error("Request setup error", error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
