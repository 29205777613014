import { useEffect, useRef, useState } from "react"; // Import useRef
import { Link, useParams } from "react-router-dom";
import authorImage from "../../../assets/images/author.jpg";
import PageTitle from "../../../components/pageTitle";
import UnAuthenticatedPlaceholder from "../../../components/unAuthenticatedPlaceholder";
import useDetails from "./util";
import getCategoryString from "../../../utils/getCategoryString";
import formatToFriendlyDate from "../../../utils/formatToFriendlyDate";

const StoryDetailsPage = () => {
  const { id } = useParams();
  const { data, error, story, token } = useDetails(id!);
  const [sharePopupVisible, setSharePopupVisible] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null); // Create a ref for the popup

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setSharePopupVisible(false); // Dismiss the popup
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  if (error) {
    return <div>Error</div>;
  }

  const handleShare = (platform: string) => {
    const currentUrl = window.location.href;
    let shareUrl = "";

    switch (platform) {
      case "copy":
        navigator.clipboard.writeText(currentUrl);
        alert("Link copied to clipboard!");
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      case "x":
        shareUrl = `https://twitter.com/share?url=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      default:
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }

    // Close popup after sharing action
    setSharePopupVisible(false);
  };

  const renderDescription = () => {
    if (token) {
      return (
        <p
          className="text-lg leading-relaxed text-gray-800"
          dangerouslySetInnerHTML={{
            __html: data?.description ?? "Description not available.",
          }}
        />
      );
    } else {
      const teaser = data?.description
        ? data.description.slice(0, 400) + "..."
        : "Description not available.";

      return (
        <div>
          <p
            className="text-lg leading-relaxed text-gray-800"
            dangerouslySetInnerHTML={{ __html: teaser }}
          />
          <UnAuthenticatedPlaceholder />
        </div>
      );
    }
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="w-full px-5 py-0 flex flex-col">
        <div>
          <div className="lg:w-4/6 mx-auto">
            {/* Category */}
            {data?.category && (
              <p className="text-lg text-primaryColor text-gray-500 text-center w-full">
                {getCategoryString(data.category).toUpperCase()}
              </p>
            )}
            <PageTitle
              text={data?.title ?? ""}
              marginTop={"mt-0"}
              marginBottom={"mb-5"}
            />
            {/* Synopsis */}
            <p className="text-xl text-gray-700 mb-4">{data?.synopsis}</p>

            {/* Published Date and Share Button */}
            <div className="flex items-center justify-between mb-4">
              <span className="text-gray-500 text-base">
                {data?.publishedDate
                  ? formatToFriendlyDate(data.publishedDate)
                  : "Date not available"}
              </span>
              <div className="relative">
                <button
                  onClick={() => setSharePopupVisible(!sharePopupVisible)}
                  className="border border-primaryColor px-4 py-1 text-primaryColor bg-white hover:bg-gray-200"
                >
                  Share
                </button>
                {sharePopupVisible && (
                  <div
                    ref={popupRef} // Attach the ref to the popup
                    className="absolute w-60 right-0 mt-2 bg-white border rounded shadow-lg p-2"
                  >
                    <button
                      onClick={() => handleShare("copy")}
                      className="text-gray-800 block px-4 py-2 hover:bg-gray-100"
                    >
                      Copy Link
                    </button>
                    <button
                      onClick={() => handleShare("facebook")}
                      className="text-gray-800 block px-4 py-2 hover:bg-gray-100"
                    >
                      Share on Facebook
                    </button>
                    <button
                      onClick={() => handleShare("x")}
                      className="text-gray-800 block px-4 py-2 hover:bg-gray-100"
                    >
                      Share on X
                    </button>
                    <button
                      onClick={() => handleShare("whatsapp")}
                      className="text-gray-800 block px-4 py-2 hover:bg-gray-100"
                    >
                      Share on WhatsApp
                    </button>
                    <button
                      onClick={() => handleShare("linkedin")}
                      className="text-gray-800 block px-4 py-2 hover:bg-gray-100"
                    >
                      Share on LinkedIn
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Article Image */}
          <div className="w-full rounded-lg overflow-hidden mb-4">
            <img
              alt="content"
              src={data?.coverImageUrl ?? ""}
              className="w-full h-auto object-cover"
            />
          </div>

          <div className="lg:w-4/6 mx-auto">
            {/* Article Content */}
            <div className="flex flex-col sm:flex-row mt-4">
              {renderDescription()}
            </div>

            {/* About the Author */}
            {token && (
              <div className="mt-8 p-6 bg-gray-100 rounded-lg mb-10">
                <h3 className="text-2xl font-bold text-gray-900 mb-4">
                  About the Author
                </h3>
                <div className="flex items-center">
                  <img
                    src={story?.authorImage ?? authorImage}
                    alt="Author"
                    className="w-16 h-16 rounded-full object-cover mr-4"
                  />
                  <div>
                    <h4 className="text-xl font-semibold text-gray-800">
                      {story?.createdBy}
                    </h4>
                    <p className="text-gray-600">
                      {story?.authorBio ??
                        "Boast a decade of experience in journalism, Zanele has published articles for top news companies ranging from News24, BBC, SABC and much more.."}
                    </p>
                    <p className="text-sm text-blue-600">
                      <Link to={`/storiesByAuthor/${data?.author?.id}`}>
                        More by {data?.author?.displayText ?? "Unknown"}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StoryDetailsPage;
