import { useEffect, useState } from "react";
import axiosInstance from "../../helpers/axiosInterceptors";
import INews from "../../interfaces/news";
import ICardComponent from "../../interfaces/cardComponent";
import convertToNewsBulletin from "../../utils/convertCardComponentToNewsBulletin";
import useGet from "../../network/useGet";
import { get } from "http";
import getMainGridCardItem from "../../utils/getMainGridCardItem";
import { IPodcastProps } from "../../interfaces/podcast";
import convertPodcastToCardComponent from "../../utils/convertPodcastToCardComponent";
import { useApp } from "../../providers/app/appContext";

const useHome = () => {
  const [stories, setStories] = useState<Array<ICardComponent>>([]);
  const [documentaries, setDocumentaries] = useState<Array<ICardComponent>>([]);
  const [podcasts, setPodcasts] = useState<Array<ICardComponent>>([]);
  const [mainDocumentary, setMainDocumentary] = useState<ICardComponent>();
  const [mainPodcast, setMainPodcast] = useState<ICardComponent>();
  const [mainStory, setMainStory] = useState<ICardComponent>();
  const { data, error } = useGet<any>(`/api/services/app/News/GetAll`);
  const { data: documentaryData, error: documentaryError } = useGet<any>(
    `/api/services/app/Documentary/GetAll`
  );
  const { error: podcastError, data: podcastData } = useGet<any>(
    "/api/services/app/Podcast/GetAll"
  );
  const { navigate } = useApp();

  useEffect(() => {
    if (data) {
      const bulletinsToCardComponents = data.items.map((newsBulletin: INews) =>
        convertToNewsBulletin(newsBulletin)
      ) as Array<ICardComponent>;
      const mainStory = getMainGridCardItem(bulletinsToCardComponents);
      setMainStory(mainStory);
      setStories(
        bulletinsToCardComponents
          .filter((item) => item.id !== mainStory.id)
          .slice(0, 3)
      );
    }

    if (error) {
      console.log(error);
    }
  }, [data, error]);

  useEffect(() => {
    if (documentaryData) {
      const bulletinsToCardComponents = documentaryData.items.map(
        (newsBulletin: INews) => convertToNewsBulletin(newsBulletin)
      ) as Array<ICardComponent>;
      const mainDocumentary = getMainGridCardItem(bulletinsToCardComponents);
      setMainDocumentary(mainDocumentary);
      setDocumentaries(
        bulletinsToCardComponents
          .filter((item) => item.id !== mainDocumentary.id)
          .slice(0, 3)
      );
    }

    if (documentaryError) {
      console.log(documentaryError);
    }
  }, [documentaryData, documentaryError]);

  useEffect(() => {
    if (podcastData) {
      const bulletinsToCardComponents = podcastData.items.map(
        (newsBulletin: IPodcastProps) =>
          convertPodcastToCardComponent(newsBulletin)
      ) as Array<ICardComponent>;
      const mainPodcast = getMainGridCardItem(bulletinsToCardComponents);
      setPodcasts(
        bulletinsToCardComponents
          .filter((item) => item.id !== mainPodcast.id)
          .slice(0, 3)
      );
      setMainPodcast(mainPodcast);
    }
    if (podcastError) {
      console.log(documentaryError);
    }
  }, [podcastData, podcastError]);

  const handleClick = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    //console.log("event###", mainStory?.externalLink);
    event.preventDefault();
    navigate({
      url: `${mainStory?.link}/${mainStory?.id}`,
      externalLink: mainStory?.externalLink,
    });
    // if (mainStory?.externalLink) {
    //   window.open(mainStory?.externalLink, "_blank"); // If the condition is met, open the external link in a new tab
    // } else {
    //   navigate(`${mainStory?.link}/${mainStory?.id}`); // If the condition is met, navigate to the target path
    // }
  };

  return {
    error,
    news: stories,
    documentaries,
    mainDocumentary,
    mainStory,
    podcasts,
    mainPodcast,
    handleClick,
  };
};

export default useHome;
