import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import axiosInstance from "../helpers/axiosInterceptors";

export type AbpResponse<T> = {
  result?: T;
  success: boolean;
  error?: {
    message: string;
    details?: string;
    validationErrors?: ValidationError[];
  };
  targetUrl?: string;
};

type ValidationError = {
  message: string;
  members?: string;
};

type ApiResponse<T> = {
  data: T | null;
  error: string | null;
  loading: boolean;
  fetchData: () => Promise<AbpResponse<T>>;
};

function useGet<T>(url: string, isLazy: boolean = false): ApiResponse<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (): Promise<AbpResponse<T>> => {
    try {
      setLoading(true);
      const response: AxiosResponse<AbpResponse<T>> = await axiosInstance.get(
        url
      );

      if (response.data.success) {
        setData(response.data.result || null);
      } else if (response.data.error) {
        setError(response.data.error.message);
      } else {
        setError("Unknown error occurred");
      }
      return response.data;
    } catch (error: any) {
      setError(error.message || "Network error occurred");
      return {
        result: undefined,
        success: false,
        error: {
          message: error.message,
        },
      };
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isLazy) fetchData();
  }, [isLazy, url]); // Added url as a dependency

  return { data, error, loading, fetchData };
}

export default useGet;
