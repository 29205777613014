import { useEffect } from "react";
import PageTitle from "../../components/pageTitle";

const PlaceholderPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <PageTitle text="Page not found" />
    </div>
  );
};

export default PlaceholderPage;
