import { FaSpinner, FaExclamationTriangle, FaInfoCircle } from "react-icons/fa";
import GridCard from "../../../components/gridCard";
import PageTitle from "../../../components/pageTitle";
import useGetByAuthor from "./util";
import { useParams } from "react-router-dom";

const PodcastsByAuthor = () => {
  const { id } = useParams();
  const { stories, error, loading, authorName } = useGetByAuthor(id ?? "");
  let name = `News by ` + authorName;

  if (loading) {
    return (
      <div className="container px-5 pt-12 pb-12 mx-auto flex flex-col items-center justify-center h-screen">
        <FaSpinner className="animate-spin text-blue-500 text-4xl mb-5" />
        <p className="text-gray-600 text-lg">Loading news...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container px-5 pt-12 pb-12 mx-auto flex flex-col items-center justify-center h-screen">
        <FaExclamationTriangle className="text-red-500 text-4xl mb-5" />
        <p className="text-red-500 text-lg">Error: {error}</p>
        <p className="text-gray-600 text-base">
          Sorry, we couldn't fetch the news. Please try again later.
        </p>
      </div>
    );
  }

  if (!stories.length) {
    return (
      <div className="container px-5 pt-12 pb-12 mx-auto flex flex-col items-center justify-center h-screen">
        <FaInfoCircle className="text-gray-600 text-4xl mb-5" />
        <p className="text-gray-600 text-lg">No stories found</p>
        <p className="text-gray-500 text-base">
          It seems there are no stories available by this author at the moment.
        </p>
      </div>
    );
  }

  return (
    <div className="container px-5 pt-12 pb-12 mx-auto">
      <PageTitle text={name} />
      <div className="flex flex-wrap mx-4">
        {stories.map((section) => (
          <GridCard key={section.id} {...section} />
        ))}
      </div>
    </div>
  );
};

export default PodcastsByAuthor;
