import React, { useEffect } from "react";

interface ToastProps {
  message: string;
  type?: "info" | "success" | "error";
  duration?: number;
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({
  message,
  type = "info",
  duration = 5000,
  onClose,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  let toastStyle = "bg-blue-500";
  if (type === "success") toastStyle = "bg-green-500";
  if (type === "error") toastStyle = "bg-red-500";

  return (
    <div
      className={`fixed top-5 left-1/2 transform -translate-x-1/2 flex items-center justify-between max-w-xs w-full p-4 mb-4 text-sm text-white rounded-lg shadow-lg ${toastStyle}`}
      role="alert"
    >
      <span>{message}</span>
      <button onClick={onClose} className="ml-2 text-white">
        <svg
          className="w-4 h-4 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414z" />
        </svg>
      </button>
    </div>
  );
};

export default Toast;
