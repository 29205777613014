import { useState } from "react";
import { useApp } from "../../providers/app/appContext";

const useMainGridCard = (url: string, externalLink: string | undefined) => {
  const { navigate } = useApp();
  const [toast, setToast] = useState<{
    message: string;
    type: "info" | "success" | "error";
  } | null>(null);

  const handleClick = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    navigate({ url, externalLink });
  };

  return { handleClick, toast, setToast };
};

export default useMainGridCard;
