import React from "react";
import IErrorLoadingEmptyPlaceholderProps from "../../interfaces/errorLoadingEmptyPlaceholder";
import { FaExclamationTriangle, FaInfoCircle, FaSpinner } from "react-icons/fa";

const ErrorLoadingEmptyPlaceholder: React.FC<
  IErrorLoadingEmptyPlaceholderProps
> = ({ error, errorMessage, isLoading, emptyMessage, isEmpty, emptyTitle }) => {
  if (isLoading) {
    return (
      <div className="container px-5 pt-12 pb-12 mx-auto flex flex-col items-center justify-center h-screen">
        <FaSpinner className="animate-spin text-blue-500 text-4xl mb-5" />
        <p className="text-gray-600 text-lg">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container px-5 pt-12 pb-12 mx-auto flex flex-col items-center justify-center h-screen">
        <FaExclamationTriangle className="text-red-500 text-4xl mb-5" />
        <p className="text-red-500 text-lg">Error: {error}</p>
        <p className="text-gray-600 text-base">{errorMessage}</p>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className="container px-5 pt-12 pb-12 mx-auto flex flex-col items-center justify-center h-screen">
        <FaInfoCircle className="text-gray-600 text-4xl mb-5" />
        <p className="text-gray-600 text-lg">{emptyTitle}</p>
        <p className="text-gray-500 text-base">{emptyMessage}</p>
      </div>
    );
  }

  return <div>ErrorLoadingEmptyPlaceholder</div>;
};

export default ErrorLoadingEmptyPlaceholder;
