import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../components/pageTitle";
import GridCard from "../../../components/gridCard";
import useGetByCategory from "./util";
import ErrorLoadingEmptyPlaceholder from "../../../components/errorLoadingEmptyPlaceholder";
import MainHeadlineCard from "../../../components/mainHeadlineCard";

const FilteredStories = () => {
  const { filter, title } = useParams();
  const { stories, loading, error, mainStory } = useGetByCategory(
    filter ?? "1"
  );
  useEffect(() => {}, [filter]);

  if (loading == true || error != null || stories?.length == 0) {
    return (
      <ErrorLoadingEmptyPlaceholder
        isLoading={loading}
        error={error ?? ""}
        isEmpty={!stories?.length}
        emptyTitle={`No ${title} news found`}
        emptyMessage={"It seems there are no news available at the moment."}
        errorMessage="Sorry, we couldn't fetch the news. Please try again later."
      />
    );
  }

  return (
    <div>
      <section className="text-gray-600 body-font">
        <PageTitle text={title ?? "News"} />
        <div className="container px-5 pt-0 pb-12 mx-auto">
          {mainStory && <MainHeadlineCard {...mainStory} />}
          <div className="mb-8"></div>
          <div className="flex flex-wrap -m-4">
            {stories.map((section) => (
              <GridCard key={section.id} {...section} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FilteredStories;
