import { FC } from "react";
import ICardComponent from "../../interfaces/cardComponent";
import { Link } from "react-router-dom";
import YouTubeVideo from "../youTubeVideo";
import useGridCard from "./util";
import Toast from "../toast/toast";

const GridCard: FC<ICardComponent> = ({
  id,
  title,
  image,
  description,
  linkText,
  category,
  link,
  videoId,
  externalLink,
}) => {
  const { handleClick, toast, setToast } = useGridCard(
    `${link}/${id}`,
    externalLink
  );

  return (
    <div className="p-4 md:w-1/3 animate-fadeInUp">
      <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out">
        {videoId ? (
          <YouTubeVideo videoId={videoId} />
        ) : (
          <img
            className="lg:h-48 md:h-36 w-full object-cover object-center"
            src={
              image ??
              "https://www.shutterstock.com/image-illustration/no-picture-available-placeholder-thumbnail-600nw-2179364083.jpg"
            }
            alt="content"
          />
        )}
        <div className="p-6 bg-white">
          <h2 className="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1 uppercase">
            {category}
          </h2>
          <h1 className="title-font text-lg font-bold text-gray-900 mb-3">
            {title}
          </h1>
          <p className="leading-relaxed text-gray-700 mb-3 line-clamp-4">
            {description}
          </p>
          <div className="flex items-center flex-wrap">
            <Link
              to={``}
              onClick={handleClick}
              className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"
            >
              {linkText}
              <svg
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
      {toast && (
        <div className="flex flex-col text-center w-full mb-12">
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        </div>
      )}
    </div>
  );
};

export default GridCard;
