import React, { ReactNode, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "./authContext";

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [user, setUser] = useState<any>(null); // Replace with a more specific type for user

  useEffect(() => {
    (async () => {
      const cachedUser = await localStorage.getItem("user");
      if (cachedUser && !user) {
        setUser(JSON.parse(cachedUser));
      }
    })();
  }, []);

  const logout = async () => {
    await localStorage.removeItem("token");
    await localStorage.removeItem("user");
    setToken(null);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        logout,
        setUser,
        setToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
