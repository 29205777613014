import { Link } from "react-router-dom";

const UnAuthenticatedPlaceholder = () => {
  return (
    <div className="mt-4 bg-gray-100 p-4 rounded-lg mb-8">
      <p className="text-lg text-gray-700 font-medium">
        To read the full article, please{" "}
        <Link to="/signin" className="text-primaryColor hover:underline">
          log in
        </Link>{" "}
        or{" "}
        <Link to="/register" className="text-primaryColor hover:underline">
          register
        </Link>
        .
      </p>
    </div>
  );
};

export default UnAuthenticatedPlaceholder;
