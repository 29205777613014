import React, { useState, useEffect } from "react";
import { useApp } from "../../providers/app/appContext";
import { Link } from "react-router-dom";

const AuthPopup: React.FC = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { showAuthPopup, setAuthPopup } = useApp();

  useEffect(() => {
    setPopupOpen(showAuthPopup);
  }, [showAuthPopup]);

  return (
    <div>
      {isPopupOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-primaryColor text-white rounded-lg shadow-lg w-[90%] max-w-lg p-8 overflow-hidden">
            {/* Close Button */}
            <button
              onClick={() => setAuthPopup(false)}
              className="absolute top-6 right-4 w-10 h-10 bg-white text-primaryColor rounded-full flex items-center justify-center text-2xl font-bold hover:opacity-90 focus:outline-none"
              aria-label="Close"
            >
              &times;
            </button>

            {/* Content */}
            <div className="text-center">
              {/* Title */}
              <h2 className="text-2xl md:text-3xl mb-4">
                Want to read further?
              </h2>

              {/* Description */}
              <p className="text-sm md:text-base text-white opacity-90 mb-6">
                Get access to full content and keep up to date with the latest
                news and stories by signing in or registering with us.
              </p>

              {/* Action Buttons */}
              <div className="md:flex-column p-5">
                <Link
                  to="/register"
                  onClick={() => setAuthPopup(false)}
                  className="inline-block px-6 py-3 me-4 text-primaryColor bg-white rounded-full shadow-md font-semibold hover:bg-gray-200 transition duration-300"
                >
                  Register
                </Link>
                <Link
                  to="/signIn"
                  onClick={() => setAuthPopup(false)}
                  className="inline-block px-6 py-3 text-primaryColor bg-white rounded-full shadow-md font-semibold hover:bg-gray-200 transition duration-300"
                >
                  Login
                </Link>
              </div>
            </div>

            {/* Full-Width Image Without Margins */}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthPopup;
