import { useEffect, useState } from "react";
import ICardComponent from "../../../interfaces/cardComponent";
import INews from "../../../interfaces/news";
import useGet from "../../../network/useGet";
import convertToNewsBulletin from "../../../utils/convertCardComponentToNewsBulletin";

const useGetByAuthor = (authorId: string) => {
  const [stories, setStories] = useState<Array<ICardComponent>>([]);
  const [authorName, setAuthorName] = useState("");
  const { data, error, loading, fetchData } = useGet<Array<INews>>(
    `/api/services/app/News/GetListByAuthor?authorId=${authorId}`,
    true
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      //console.log(data);
      setAuthorName(data[0]?.author?.displayText ?? "Unknown");
      const modifiedNews = data?.map((newsBulletin: INews) =>
        convertToNewsBulletin(newsBulletin)
      ) as Array<ICardComponent>;
      setStories(modifiedNews);
    }

    if (error) {
      console.log(error);
    }
  }, [data, error]);
  return {
    stories,
    error,
    loading,
    authorName,
  };
};

export default useGetByAuthor;
