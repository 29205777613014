import React, { createContext, useContext, useState, ReactNode } from "react";
import axios, { AxiosRequestConfig } from "axios";
import AuthContextType from "./interface";

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
