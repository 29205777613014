import { useCallback, useEffect, useRef, useState } from "react";
import INews from "../../interfaces/news";
import ICardComponent from "../../interfaces/cardComponent";
import convertToNewsBulletin from "../../utils/convertCardComponentToNewsBulletin";
import useGet from "../../network/useGet";

interface NewsProps {
  items: Array<INews>;
}

const useNews = () => {
  const [stories, setStories] = useState<Array<ICardComponent>>([]);
  const [mainStory, setMainStory] = useState<ICardComponent | undefined>();
  const { error, loading, fetchData } = useGet<NewsProps>(
    `/api/services/app/News/GetAll`,
    true
  );

  const isMounted = useRef(false);

  const fetchDataCallback = useCallback(() => {
    fetchData().then((response) => {
      if (response.success) {
        const modifiedNews = response.result?.items?.map(
          (newsBulletin: INews) => convertToNewsBulletin(newsBulletin)
        ) as Array<ICardComponent>;

        const headline = getHeadline(modifiedNews);
        if (headline && headline.id) {
          const remainingArticles = modifiedNews.filter(
            (article) => article.id !== headline.id
          );
          setMainStory(headline);
          setStories(remainingArticles);
        } else {
          setMainStory(undefined);
          setStories(modifiedNews);
        }
      }
    });
  }, [fetchData]);

  useEffect(() => {
    if (!isMounted.current) {
      fetchDataCallback();
      isMounted.current = true;
    }
  }, [fetchDataCallback]);

  function findLatestHeadline(
    cards: ICardComponent[]
  ): ICardComponent | undefined {
    return cards
      .filter((card) => card.isHeadline && card.publishedDate)
      .reduce((latest, current) => {
        if (
          !latest ||
          (latest.publishedDate &&
            current.publishedDate &&
            current.publishedDate > latest.publishedDate)
        ) {
          return current;
        }
        return latest;
      }, undefined as ICardComponent | undefined);
  }

  function findLatestStory(
    cards: ICardComponent[]
  ): ICardComponent | undefined {
    return cards
      .filter((card) => card.publishedDate)
      .reduce((latest, current) => {
        if (
          !latest ||
          (latest.publishedDate &&
            current.publishedDate &&
            current.publishedDate > latest.publishedDate)
        ) {
          return current;
        }
        return latest;
      }, undefined as ICardComponent | undefined);
  }

  function getHeadline(cards: ICardComponent[]): ICardComponent | undefined {
    const headline = findLatestHeadline(cards);
    if (headline) {
      return headline;
    }
    return findLatestStory(cards);
  }

  return {
    stories,
    mainStory,
    error,
    loading,
  };
};

export default useNews;
