import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Header from "./components/header";
import HomePage from "./pages/home";
import DocumentariesPage from "./pages/documentaries";
import StoriesPage from "./pages/stories";
import PodcastsPage from "./pages/podcasts";
import ContactUsPage from "./pages/contact-us";
import SubscribePage from "./pages/subscribe";
import Footer from "./components/footer";
import StoryDetailsPage from "./pages/stories/details";
import AboutPage from "./pages/about";
import NewslettersPage from "./pages/newsletters";
import PrivacyPolicyPage from "./pages/privacy-policy";
import FilteredStories from "./pages/stories/filtered";
import SignInPage from "./pages/signIn";
import RegisterPage from "./pages/register";
import AuthProvider from "./providers/auth/index";
import ProfilePage from "./pages/profile";
import StoriesByAuthor from "./pages/stories/byAuthor";
import DocumentariesDetailsPage from "./pages/documentaries/details";
import BuyMeACoffee from "./pages/buyMeACoffee";
import PodCastDetails from "./pages/podcasts/details";
import PlaceholderPage from "./pages/placeholder";
import PodcastsByAuthor from "./pages/podcasts/byAuthor";
import DonationsPolicyPage from "./pages/donations-policy";
import AdvertisePage from "./pages/advertise";
import AppProvider from "./providers/app";
import DonatePopup from "./components/donatePopup";
import AdvertiseWithUsPopup from "./components/advertiseWithUsPopup";
import AuthPopup from "./components/authPopup";

const pageVariants = {
  initial: { opacity: 0, x: 100 },
  enter: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 },
};

const pageTransition = {
  duration: 0.5,
};

const PageWrapper = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <motion.div
        key={location.pathname}
        initial="initial"
        animate="enter"
        exit="exit"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Routes location={location} key={location.pathname}>
          <Route path="/home" element={<HomePage />} />
          <Route path="/news" element={<StoriesPage />} />
          <Route path="/documentaries" element={<DocumentariesPage />} />
          <Route path="/podcasts" element={<PodcastsPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/subscribe" element={<SubscribePage />} />
          <Route path="/storyDetails/:id" element={<StoryDetailsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/newsletters" element={<NewslettersPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/signIn" element={<SignInPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/buyMeACoffee" element={<BuyMeACoffee />} />
          <Route path="/placeholderPage" element={<PlaceholderPage />} />
          <Route path="/advertise" element={<AdvertisePage />} />
          <Route
            path="/filteredStories/:filter/:title"
            element={<FilteredStories />}
          />
          <Route path="/" element={<HomePage />} />
          <Route path="/storiesByAuthor/:id" element={<StoriesByAuthor />} />
          <Route path="/podcastsByAuthor/:id" element={<PodcastsByAuthor />} />
          <Route
            path="/documentaryDetails/:id"
            element={<DocumentariesDetailsPage />}
          />
          <Route path="/podcastDetails/:id" element={<PodCastDetails />} />
          <Route path="/donations-policy" element={<DonationsPolicyPage />} />
        </Routes>
      </motion.div>
    </AnimatePresence>
  );
};

const App = () => {
  const footerRef = useRef<HTMLDivElement>(null);

  const scrollToFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Router>
      <AuthProvider>
        <AppProvider>
          <div className="pt-[6rem] w-full">
            <Header onAboutClick={scrollToFooter} />
            <PageWrapper />
            <div ref={footerRef}>
              <Footer />
            </div>
            <AuthPopup />
            <DonatePopup />
            <AdvertiseWithUsPopup />
          </div>
        </AppProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
