import { useEffect, useState } from "react";
import useGet from "../../network/useGet";
import { IPodcastProps } from "../../interfaces/podcast";
import ICardComponent from "../../interfaces/cardComponent";
import convertPodcastToCardComponent from "../../utils/convertPodcastToCardComponent";
import getMainGridCardItem from "../../utils/getMainGridCardItem";

const usePodcasts = () => {
  const { fetchData, error, loading } = useGet<any>(
    "/api/services/app/Podcast/GetAll",
    true
  );

  const [podcasts, setPodcasts] = useState<Array<IPodcastProps>>([]);
  const [cardItems, setCardItems] = useState<Array<ICardComponent>>([]);
  const [mainPodcast, setMainPodcast] = useState<ICardComponent>();

  useEffect(() => {
    fetchData().then((response) => {
      if (response?.result?.items) {
        const podscast = response?.result?.items as Array<IPodcastProps>;

        const cardItems = podscast?.map((podcast) => {
          return convertPodcastToCardComponent(podcast);
        });

        const mainPodcast = getMainGridCardItem(cardItems);
        setMainPodcast(mainPodcast);
        setPodcasts(podscast);
        setCardItems(cardItems.filter((item) => item.id !== mainPodcast.id));
      }
    });
  }, []);

  return {
    fetchData,
    error,
    loading,
    cardItems,
    mainPodcast,
  };
};

export default usePodcasts;
