import React from "react";
import { ISectionTitleProps } from "./interface";

const SectionTitle: React.FC<ISectionTitleProps> = ({
  text,
  color = "text-gray-900", // default color class
  fontWeight = "font-bold", // default font weight class
  fontSize = "text-2xl sm:text-3xl", // default font size classes
}) => {
  return (
    <div className="flex items-center justify-center mb-10">
      <div
        className="flex-grow bg-primaryColor animate-slide-right"
        style={{ height: "2px", maxWidth: "10%" }} // Adjust maxWidth here
      ></div>
      <h1
        className={`px-4 title-font ${color} ${fontWeight} ${fontSize}`}
        style={{ whiteSpace: "nowrap" }}
      >
        {text}
      </h1>
      <div
        className="flex-grow bg-primaryColor animate-slide-right"
        style={{ height: "2px", maxWidth: "10%" }} // Adjust maxWidth here
      ></div>
    </div>
  );
};

export default SectionTitle;
