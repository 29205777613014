import { AxiosError } from "axios";
import { AbpResponse } from "../network/useGet";

function extractAbpError(error: unknown): string | undefined {
  const axiosError = error as AxiosError;
  if (axiosError) {
    const response = axiosError?.response?.data as AbpResponse<any>;
    const validationErrors = response?.error?.validationErrors;
    if (validationErrors && validationErrors.length > 0) {
      return validationErrors
        .map((err) => err.message ?? "")
        .join("\n")
        .trim();
    } else if (response?.error?.details) {
      return response?.error?.details;
    } else {
      return response?.error?.message;
    }
  }
  return undefined;
}

export default extractAbpError;
