import { useEffect } from "react";
import PageTitle from "../../components/pageTitle";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="container mx-auto p-6 mt-10 mb-10">
        {/* About Us Section */}
        <section className="mb-8">
          <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
            About Us
          </h1>
          <p className="text-lg text-gray-600 text-center max-w-3xl mx-auto">
            Welcome to <span className="font-bold text-gray-800">Uginno</span>,
            your premier destination for insightful and diverse media content.
            We bring you the latest in news, podcasts, documentaries, and
            stories that matter. While our focus is on South Africa, our content
            extends globally, ensuring that we provide diverse perspectives on
            issues that impact the world.
          </p>
        </section>

        {/* Mission Section */}
        <section className="mb-8">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Our Mission
          </h2>
          <p className="text-lg text-gray-600">
            Our mission is simple: to keep you informed, engaged, and
            entertained with relevant, thought-provoking content. Whether you're
            interested in politics, business, lifestyle, or sports, Uginno
            offers a platform where all voices are heard, and diverse viewpoints
            are celebrated.
          </p>
        </section>

        {/* What We Offer Section */}
        <section className="mb-8">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            What We Offer
          </h2>

          {/* Podcasts */}
          <div className="mb-6">
            <h3 className="text-2xl font-medium text-gray-700 mb-2">
              Podcasts
            </h3>
            <p className="text-lg text-gray-600">
              Tune into Uginno's podcasts for compelling conversations and deep
              dives into current events, trends, and timeless stories. Topics
              include:
            </p>
            <ul className="list-disc list-inside text-gray-600 mt-2">
              <li>Politics</li>
              <li>Business and Economics</li>
              <li>Entertainment and Pop Culture</li>
              <li>Health and Wellness</li>
              <li>Sports Highlights</li>
            </ul>
          </div>

          {/* News */}
          <div className="mb-6">
            <h3 className="text-2xl font-medium text-gray-700 mb-2">News</h3>
            <p className="text-lg text-gray-600">
              Stay up-to-date with the latest happenings across a wide range of
              topics:
            </p>
            <ul className="list-disc list-inside text-gray-600 mt-2">
              <li>
                <strong className="text-gray-800">Politics:</strong> In-depth
                analysis and breaking news on South African and international
                politics.
              </li>
              <li>
                <strong className="text-gray-800">Business:</strong> Insights
                into the world of finance, startups, and global markets.
              </li>
              <li>
                <strong className="text-gray-800">Lifestyle:</strong> Wellness,
                fashion, food, and travel tips for modern living.
              </li>
              <li>
                <strong className="text-gray-800">Sports:</strong> Coverage of
                all major sporting events, from soccer to rugby and more.
              </li>
              <li>
                <strong className="text-gray-800">Entertainment:</strong> Get
                the latest from the world of film, music, and TV.
              </li>
            </ul>
          </div>

          {/* Documentaries */}
          <div>
            <h3 className="text-2xl font-medium text-gray-700 mb-2">
              Documentaries
            </h3>
            <p className="text-lg text-gray-600">
              Uginno also brings you captivating documentaries that provide
              in-depth explorations into significant events, people, and
              stories. From social issues to historical milestones, our
              documentaries offer a deeper understanding of the world around us.
            </p>
          </div>
        </section>

        {/* Our Reach Section */}
        <section className="mb-8">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Our Reach
          </h2>
          <p className="text-lg text-gray-600">
            Though we proudly cater to South African audiences, our stories,
            analysis, and discussions span across continents. We believe in
            building a global community of listeners and readers who are
            passionate about understanding the world and making informed
            decisions.
          </p>
        </section>

        {/* Join Us Section */}
        <section>
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">Join Us</h2>
          <p className="text-lg text-gray-600">
            At Uginno, we believe that media should inspire, inform, and bring
            people together. We are more than just a media platform; we are a
            community. Join us today, explore our podcasts, documentaries, and
            news stories, and become part of a growing audience that values
            quality content, diverse opinions, and engaging conversations.
          </p>
          <p className="text-2xl font-bold text-gray-800 mt-6">
            Uginno – Your World, Your Stories.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutPage;
