import { useEffect, useState } from "react";
import { IPodcastProps } from "../../../interfaces/podcast";
import useGet from "../../../network/useGet";

const useDetails = (id: string) => {
  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    setToken(storedToken);
  }, []);

  const { data, error, loading } = useGet<IPodcastProps>(
    `/api/services/app/Podcast/Get?Id=${id}`
  );
  return {
    loading,
    error,
    podcast: data,
    token,
  };
};

export default useDetails;
