import { FC } from "react";
import ICardComponent from "../../interfaces/cardComponent";
import { Link } from "react-router-dom";
import useMainGridCard from "./util";

const MainHeadlineCard: FC<ICardComponent> = ({
  id,
  title,
  image,
  description,
  linkText,
  category,
  link,
  videoId,
  externalLink,
}) => {
  const { handleClick } = useMainGridCard(`${link}/${id}`, externalLink);
  return (
    <div className="w-full p-0">
      <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:shadow-lg">
        {videoId ? (
          <iframe
            className="w-full h-80 md:h-96 lg:h-[28rem] xl:h-[32rem] object-none object-center"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        ) : (
          <img
            className="w-full h-80 md:h-96 lg:h-[28rem] xl:h-[32rem] object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
            src={
              image ??
              "https://www.shutterstock.com/image-illustration/no-picture-available-placeholder-thumbnail-600nw-2179364083.jpg"
            }
            alt="headline"
          />
        )}

        <div className="p-6">
          <h2 className="tracking-widest text-xs title-font font-medium mb-1 text-indigo-500">
            {category}
          </h2>
          <h1 className="title-font text-3xl font-bold mb-3">{title}</h1>
          <p className="leading-relaxed mb-3 line-clamp-4">{description}</p>
          <div className="flex items-center flex-wrap">
            <Link
              to={``}
              onClick={handleClick}
              className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0 transition-transform duration-300 ease-in-out transform hover:translate-x-1 hover:text-indigo-600"
            >
              {linkText}
              <svg
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeadlineCard;
