import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackendUrl, LoginApi, RegisterApi } from "../../constants/apis";
import axiosInstance from "../../helpers/axiosInterceptors";
import { useAuth } from "../../providers/auth/authContext";
import extractAbpError from "../../utils/extractAbpError";
import RegisterUserProps from "./interface";

const useRegister = () => {
  const [error, setError] = useState("");
  const { setToken, setUser } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<RegisterUserProps>({
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
    passwordConfirmation: "",
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      console.log(formData);
      setError("");

      if (
        !formData.firstName ||
        !formData.lastName ||
        !formData.emailAddress ||
        !formData.password ||
        !formData.passwordConfirmation ||
        !formData.mobileNumber
      ) {
        setError("Please fill in all fields.");
        return;
      }

      if (formData.password !== formData.passwordConfirmation) {
        setError("Passwords do not match");
        return;
      }

      // Make API call to sign in

      const userInfoObj = await axios.post(
        BackendUrl + RegisterApi,
        {
          ...formData,
          userName: formData.emailAddress,
        },
        {
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
            Authorization: "null",
          },
        }
      );

      const response = await axiosInstance.post(LoginApi, {
        userNameOrEmailAddress: formData.emailAddress,
        password: formData.password,
        rememberClient: true,
      });

      if (userInfoObj.status !== 200 || response.status !== 200) {
        setError("Registering failed. Please try again.");
        return;
      } else {
        console.log(userInfoObj.data);
        await localStorage.setItem(
          "token",
          response?.data?.result?.accessToken
        );
        setUser(JSON.stringify(userInfoObj.data.result));
        setToken(response?.data?.result?.accessToken);
        navigate("/home");
      }
    } catch (error) {
      const validationErrors = extractAbpError(error);
      setError(validationErrors ?? "Registering failed. Please try again.");
    }
  };

  return {
    formData,
    handleSubmit,
    error,
    setFormData,
  };
};

export default useRegister;
