import { useCallback, useEffect, useRef, useState } from "react";
import useGet from "../../network/useGet";
import { ContentInterface } from "./interfaces";
import convertToNewsBulletin from "../../utils/convertCardComponentToNewsBulletin";
import convertPodcastToCardComponent from "../../utils/convertPodcastToCardComponent";
import INews from "../../interfaces/news";
import ICardComponent from "../../interfaces/cardComponent";

const useNewsLetters = () => {
  const { error, loading, data, fetchData } = useGet<any>(
    `/api/services/app/Newsletter/GetAll`,
    true
  );
  const isMounted = useRef(false);
  const [stories, setStories] = useState<Array<ICardComponent>>([]);
  const [documentaries, setDocumentaries] = useState<Array<ICardComponent>>([]);
  const [podcasts, setPodcasts] = useState<Array<ICardComponent>>([]);
  const [commentaryAndAnalysis, setCommentaryAndAnalysis] = useState<
    Array<ICardComponent>
  >([]);
  const [trends, setTrends] = useState<Array<ICardComponent>>([]);
  const [videos, setVideos] = useState<Array<ICardComponent>>([]);
  const [features, setFeatures] = useState<Array<ICardComponent>>([]);
  const [latestNewsLetter, setLatestNewsLetter] = useState<ContentInterface>();

  const fetchDataCallback = useCallback(() => {
    fetchData().then((response) => {
      if (response.success) {
        const newLetterResponse = response.result
          ?.items as Array<ContentInterface>;
        if (newLetterResponse?.length) {
          const lastNewsLetter =
            newLetterResponse[newLetterResponse.length - 1]; //Always get the latest newsletter

          console.log(lastNewsLetter);
          setLatestNewsLetter(lastNewsLetter);
          if (lastNewsLetter) {
            if (lastNewsLetter?.news) {
              setStories(returnICardComponent(lastNewsLetter?.news));
            }
            if (lastNewsLetter?.documentaries) {
              setDocumentaries(
                returnICardComponent(lastNewsLetter?.documentaries)
              );
            }

            if (lastNewsLetter?.podcast) {
              setPodcasts(
                lastNewsLetter?.podcast.map((podcast: any) =>
                  convertPodcastToCardComponent(podcast)
                ) as Array<ICardComponent>
              );
            }

            if (lastNewsLetter?.commentaryAndAnalysis) {
              setCommentaryAndAnalysis(
                returnICardComponent(lastNewsLetter?.commentaryAndAnalysis)
              );
            }

            if (lastNewsLetter?.trends) {
              setTrends(returnICardComponent(lastNewsLetter?.trends));
            }
            if (lastNewsLetter?.videos) {
              setVideos(returnICardComponent(lastNewsLetter?.videos));
            }

            if (lastNewsLetter?.features) {
              setFeatures(returnICardComponent(lastNewsLetter?.features));
            }
          }
        } else {
        }
      }
    });
  }, [fetchData]);

  function returnICardComponent(
    newsBulletins: Array<INews>
  ): Array<ICardComponent> {
    return newsBulletins?.map((newsBulletin: INews) =>
      convertToNewsBulletin(newsBulletin)
    ) as Array<ICardComponent>;
  }

  useEffect(() => {
    if (!isMounted.current) {
      fetchDataCallback();
      isMounted.current = true;
    }
  }, [fetchDataCallback]);

  return {
    videos,
    stories,
    commentaryAndAnalysis,
    trends,
    podcasts,
    documentaries,
    error,
    loading,
    latestNewsLetter,
    features,
  };
};

export default useNewsLetters;
