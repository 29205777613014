import React, { useEffect } from "react";
import { motion } from "framer-motion";
import coffeeImage from "../../assets/images/coffee.jpg";

const BuyMeACoffeePage: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js";
    script.setAttribute("data-name", "bmc-button");
    script.setAttribute("data-slug", "zarleaks");
    script.setAttribute("data-color", "#5F7FFF");
    script.setAttribute("data-emoji", "");
    script.setAttribute("data-font", "Cookie");
    script.setAttribute("data-text", "Buy me a coffee");
    script.setAttribute("data-outline-color", "#000000");
    script.setAttribute("data-font-color", "#ffffff");
    script.setAttribute("data-coffee-color", "#FFDD00");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white py-8 px-4">
      <motion.img
        src={coffeeImage}
        alt="Coffee"
        className="w-full max-w-md rounded-lg shadow-lg mb-6 transition-transform duration-300 ease-in-out"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        whileHover={{ scale: 1.05 }}
      />
      <motion.h1
        className="text-4xl font-bold text-gray-800 mb-4"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Support Our Work
      </motion.h1>
      <motion.p
        className="text-lg text-gray-600 mb-6 text-center max-w-lg"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        Your support helps us keep this site running and allows us to continue
        providing high-quality content. If you enjoy what we do and want to help
        us out, buying us a coffee is a simple and effective way to show your
        appreciation.
      </motion.p>
      <motion.a
        href="https://www.buymeacoffee.com/zarleaks"
        target="_blank"
        rel="noreferrer"
        className="inline-flex items-center justify-center bg-primaryColor text-white font-semibold py-2 px-4 rounded-md shadow-md hover:bg-primaryColor-dark transition-colors duration-300"
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.3 }}
      >
        <span className="mr-2">Buy Me a Coffee</span>
        <svg
          className="w-4 h-4"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M5 12h14"></path>
          <path d="M12 5l7 7-7 7"></path>
        </svg>
      </motion.a>
      {/* Donations Policy Link */}
      <motion.a
        href="/donations-policy"
        className="mt-4 text-base text-primaryColor hover:underline"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        Read our Donations Policy first
      </motion.a>
    </div>
  );
};

export default BuyMeACoffeePage;
