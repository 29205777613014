import { useEffect } from "react";
import GridCard from "../../components/gridCard";
import PageTitle from "../../components/pageTitle";
import useNews from "./util";
import { FaSpinner, FaExclamationTriangle, FaInfoCircle } from "react-icons/fa";
import ICardComponent from "../../interfaces/cardComponent";
import ErrorLoadingEmptyPlaceholder from "../../components/errorLoadingEmptyPlaceholder";
import MainHeadlineCard from "../../components/mainHeadlineCard";

const StoriesPage = () => {
  const { stories: news, mainStory, loading, error } = useNews();

  if (loading || error || !news?.length) {
    return (
      <ErrorLoadingEmptyPlaceholder
        isLoading={loading}
        error={error ?? ""}
        isEmpty={!news?.length}
        emptyTitle="No news found"
        emptyMessage="It seems there are no news available at the moment."
        errorMessage="Sorry, we couldn't fetch the news. Please try again later."
      />
    );
  }

  return (
    <div className="mx-4">
      <PageTitle text="News" />
      <div className="container px-5 pt-12 pb-12 mx-auto">
        {mainStory && <MainHeadlineCard {...mainStory} />}
        <div className="mb-8"></div>
        <div className="flex flex-wrap -m-4">
          {news?.map((item: ICardComponent) => (
            <GridCard key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StoriesPage;
