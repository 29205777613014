import React, { useState, useEffect } from "react";
import adImage from "../../assets/images/ad.jpg";

const AdvertiseWithUsPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleClose = () => {
    setShowPopup(false);
    // Set the next popup time for 1 day
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    localStorage.setItem("advertisePopupNextTime", nextDay.toISOString());
  };

  const handleAdvertise = () => {
    setShowPopup(false);
    // Set the next popup time for 7 days
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    localStorage.setItem("advertisePopupNextTime", nextWeek.toISOString());
  };

  useEffect(() => {
    const nextPopupTime = localStorage.getItem("advertisePopupNextTime");
    const now = new Date();

    // Determine if the popup should be shown
    if (!nextPopupTime || new Date(nextPopupTime) <= now) {
      const timer = setTimeout(() => setShowPopup(true), 180000); // Show after 180 seconds
      return () => clearTimeout(timer); // Cleanup timer
    }
  }, []);

  if (!showPopup) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-primaryColor text-white rounded-lg shadow-lg w-[90%] max-w-lg p-8 overflow-hidden">
        {/* Close Button */}
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 w-10 h-10 bg-white text-primaryColor rounded-full flex items-center justify-center text-2xl font-bold hover:opacity-90 focus:outline-none"
          aria-label="Close"
        >
          &times;
        </button>

        {/* Content */}
        <div className="text-center">
          {/* Title */}
          <h2 className="text-2xl md:text-3xl mb-4">Advertise With Us</h2>

          {/* Description */}
          <p className="text-sm md:text-base text-white opacity-90 mb-6">
            Reach a larger audience by advertising with us. Contact us now to
            learn more about how we can help you grow your business!
          </p>

          {/* Contact Information */}
          <div className="space-y-4">
            <a
              href="mailto:advertise@uginno.com"
              onClick={handleAdvertise}
              className="block text-base underline hover:no-underline"
            >
              advertise@uginno.com
            </a>
            <a
              href="tel:+277177645518"
              onClick={handleAdvertise}
              className="block text-base underline hover:no-underline"
            >
              +27717645518
            </a>
          </div>
        </div>

        {/* Full-Width Image Without Margins */}
        <div className="mt-6 -mb-8 -mx-8">
          <img
            src={adImage}
            alt="Advertise Visual"
            className="w-full h-28 object-cover rounded-b-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default AdvertiseWithUsPopup;
