import React, { useState, useEffect } from "react";
import coffeeImage from "../../assets/images/coffee.jpg";

const DonatePopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleClose = () => {
    setShowPopup(false);
    // Set the next popup time for 1 day
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    localStorage.setItem("donatePopupNextTime", nextDay.toISOString());
  };

  const handleDonate = () => {
    setShowPopup(false);
    // Set the next popup time for 7 days
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    localStorage.setItem("donatePopupNextTime", nextWeek.toISOString());
  };

  useEffect(() => {
    const nextPopupTime = localStorage.getItem("donatePopupNextTime");
    const now = new Date();

    // Determine if the popup should be shown
    if (!nextPopupTime || new Date(nextPopupTime) <= now) {
      const timer = setTimeout(() => setShowPopup(true), 60000); // Show after 60 seconds
      return () => clearTimeout(timer); // Cleanup timer
    }
  }, []);

  if (!showPopup) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-primaryColor text-white rounded-lg shadow-lg w-[90%] max-w-lg p-8 overflow-hidden">
        {/* Close Button */}
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 w-10 h-10 bg-white text-primaryColor rounded-full flex items-center justify-center text-2xl font-bold hover:opacity-90 focus:outline-none"
          aria-label="Close"
        >
          &times;
        </button>

        {/* Content */}
        <div className="text-center">
          {/* Title */}
          <h2 className="text-2xl md:text-3xl mb-4">
            Support Independent Journalism
          </h2>

          {/* Description */}
          <p className="text-sm md:text-base text-white opacity-90 mb-6">
            Your support keeps our news free and unbiased. Help us make a
            difference by buying us a coffee. It only takes a moment to
            contribute!
          </p>

          {/* Buy Me a Coffee Button */}
          <a
            href="https://www.buymeacoffee.com/zarleaks"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-6 py-3 text-primaryColor bg-white rounded-full shadow-md font-semibold hover:bg-gray-200 transition duration-300"
            onClick={handleDonate}
          >
            ☕ Buy Me a Coffee
          </a>
        </div>

        {/* Full-Width Image Without Margins */}
        <div className="mt-6 -mb-8 -mx-8">
          <img
            src={coffeeImage}
            alt="Support Visual"
            className="w-full h-36 object-cover rounded-b-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default DonatePopup;
