import { useEffect, useState } from "react";
import useGet from "../../network/useGet";
import INews from "../../interfaces/news";
import ICardComponent from "../../interfaces/cardComponent";
import convertToGridCardItem from "../../utils/convertCardComponentToNewsBulletin";
import getMainGridCardItem from "../../utils/getMainGridCardItem";

interface NewsProps {
  items: Array<INews>;
}

const useDocumentaries = () => {
  const [documentaries, setDocumentaries] = useState<Array<ICardComponent>>([]);
  const [mainDocumentary, setMainDocumentary] = useState<ICardComponent>();
  const {
    data: documentaryData,
    fetchData,
    loading,
    error,
  } = useGet<NewsProps>(`/api/services/app/Documentary/GetAll`, true);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (documentaryData) {
      const bulletinsToCardComponents = documentaryData.items.map(
        (newsBulletin: INews) => convertToGridCardItem(newsBulletin)
      ) as Array<ICardComponent>;
      const mainDocumentary = getMainGridCardItem(bulletinsToCardComponents);
      setMainDocumentary(mainDocumentary);
      setDocumentaries(
        bulletinsToCardComponents.filter(
          (item) => item.id !== mainDocumentary.id
        )
      );
    }
  }, [documentaryData]);

  return {
    mainDocumentary,
    documentaries,
    error,
    loading,
  };
};

export default useDocumentaries;
