import { useEffect } from "react";
import PageTitle from "../../components/pageTitle";
import GridCard from "../../components/gridCard";
import usePodcasts from "./util";
import { FaExclamationTriangle, FaInfoCircle, FaSpinner } from "react-icons/fa";
import ErrorLoadingEmptyPlaceholder from "../../components/errorLoadingEmptyPlaceholder";
import MainHeadlineCard from "../../components/mainHeadlineCard";

const PodcastsPage = () => {
  const { loading, error, cardItems, mainPodcast } = usePodcasts();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading || error || !cardItems?.length) {
    return (
      <ErrorLoadingEmptyPlaceholder
        isLoading={loading}
        error={error ?? ""}
        isEmpty={!cardItems?.length}
        emptyTitle="No podcasts found"
        emptyMessage="It seems there are no podcasts available at the moment."
        errorMessage="Sorry, we couldn't fetch the podcasts. Please try again later."
      />
    );
  }

  if (loading) {
    return (
      <div className="container px-5 pt-12 pb-12 mx-auto flex flex-col items-center justify-center h-screen">
        <FaSpinner className="animate-spin text-blue-500 text-4xl mb-5" />
        <p className="text-gray-600 text-lg">Loading podcasts...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container px-5 pt-12 pb-12 mx-auto flex flex-col items-center justify-center h-screen">
        <FaExclamationTriangle className="text-red-500 text-4xl mb-5" />
        <p className="text-red-500 text-lg">Error: {error}</p>
        <p className="text-gray-600 text-base">
          Sorry, we couldn't fetch the podcasts. Please try again later.
        </p>
      </div>
    );
  }

  if (!cardItems?.length) {
    return (
      <div className="container px-5 pt-12 pb-12 mx-auto flex flex-col items-center justify-center h-screen">
        <FaInfoCircle className="text-gray-600 text-4xl mb-5" />
        <p className="text-gray-600 text-lg">No news found</p>
        <p className="text-gray-500 text-base">
          It seems there are no podcasts available at the moment.
        </p>
      </div>
    );
  }

  return (
    <div className="mx-4">
      <PageTitle text="Podcasts" />
      <div className="flex flex-wrap -m-4 mx-4">
        <MainHeadlineCard {...mainPodcast} />
        <div className="mb-8"></div>
        {cardItems?.map((section) => (
          <GridCard key={section.id} {...section} />
        ))}
      </div>
    </div>
  );
};

export default PodcastsPage;
