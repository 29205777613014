import React from "react";
import { IPageTitleProps } from "./interface";

const PageTitle: React.FC<IPageTitleProps> = ({
  text,
  color = "text-gray-900", // default color class
  fontWeight = "font-bold", // default font weight class
  fontSize = "text-2xl sm:text-6xl", // larger default font size classes
  marginTop = "mt-10",
  marginBottom = "mb-10",
}) => {
  return (
    <h1
      className={`title-font text-center ${color} ${fontWeight} ${fontSize} ${marginTop}  ${marginBottom}`}
    >
      {text}
    </h1>
  );
};

export default PageTitle;
