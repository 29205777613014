import { useEffect } from "react";
import PageTitle from "../../components/pageTitle";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <PageTitle text="Privacy Policy" />
      <div className="container mx-auto p-6 mt-10 mb-10">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">
          Terms and Conditions
        </h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">
            1. Introduction
          </h2>
          <p className="mb-4">
            Welcome to Uginno. These Terms and Conditions outline the rules and
            regulations for the use of our website and services. By accessing or
            using our website, you agree to comply with and be bound by these
            terms.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">
            2. User Responsibilities
          </h2>
          <p className="mb-4">
            Users are responsible for maintaining the confidentiality of their
            account information and for all activities that occur under their
            account. You agree to notify us immediately of any unauthorized use
            of your account.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">
            3. Content Ownership
          </h2>
          <p className="mb-4">
            All content provided on Uginno is the property of Uginno and its
            content providers. You may not copy, reproduce, distribute, or
            create derivative works of any content without explicit permission.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">
            4. Prohibited Activities
          </h2>
          <p className="mb-4">
            You agree not to engage in any activities that could harm the
            functionality or security of our website or services. This includes
            but is not limited to, uploading malicious software or engaging in
            unauthorized access.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">
            5. Limitation of Liability
          </h2>
          <p className="mb-4">
            Uginno will not be liable for any indirect, incidental, or
            consequential damages arising from the use of our services. Our
            liability is limited to the fullest extent permitted by law.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">
            6. Changes to Terms
          </h2>
          <p className="mb-4">
            We reserve the right to update these Terms and Conditions at any
            time. Any changes will be effective immediately upon posting. It is
            your responsibility to review these terms periodically.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">
            7. Contact Information
          </h2>
          <p className="mb-4">
            If you have any questions about these Terms and Conditions, please
            contact us at support@Uginno.com.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
