import ICardComponent from "../interfaces/cardComponent";
import { IPodcastProps } from "../interfaces/podcast";
import getCategoryString from "./getCategoryString";

const convertPodcastToCardComponent = (
  podcast: IPodcastProps
): ICardComponent => {
  //console.log(podcast?.category);
  return {
    id: podcast?.id ?? "",
    title: podcast?.title ?? "Title",
    description: podcast?.description ?? "Description",
    category: getCategoryString(podcast?.category ?? 0) ?? "",
    createdDate: podcast?.publishedDate ?? "",
    createdBy: podcast?.author?.displayText ?? "Unknown",
    link: "/podcastDetails",
    linkText: "Read more",
    videoId: podcast?.fileUrl ?? "",
    duration: podcast?.duration ?? "",
    orderIndex: podcast?.orderIndex ?? 0,
    externalLink: podcast?.externalLink ?? "",
    publishedDate: new Date(podcast?.publishedDate ?? ""),
  };
};

export default convertPodcastToCardComponent;
