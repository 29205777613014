import { useEffect, useState } from "react";
import { Frequency, INewsletterSubscription } from "./interface";
import { useAuth } from "../../providers/auth/authContext";
import axiosInstance from "../../helpers/axiosInterceptors";
import { SubscribeToNewsletterApi } from "../../constants/apis";
import { useNavigate } from "react-router-dom";

const useSubscribe = () => {
  const [user, setUser] = useState<any>(null);
  const navigate = useNavigate();
  const [toast, setToast] = useState<{
    message: string;
    type: "info" | "success" | "error";
  } | null>(null);
  const [frequency, setFrequency] = useState<Frequency>("daily");
  const [form, setForm] = useState<INewsletterSubscription | undefined>({
    email: "",
    preferredFrequency: 1,
    consentGiven: false,
  });
  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      const cachedUser = await localStorage.getItem("user");
      if (cachedUser) {
        setUser(JSON.parse(cachedUser));
      }
    })();
  }, []);

  const subscribeHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!user) {
        setToast({ message: "Please login first", type: "error" });
        return;
      }

      const payload: INewsletterSubscription = {
        email: user.emailAddress1,
        name: user.firstName,
        preferredFrequency:
          frequency == "daily" ? 1 : frequency == "weekly" ? 2 : 3,
        consentGiven: form?.consentGiven,
        personId: user.id,
      };

      await axiosInstance
        .post(SubscribeToNewsletterApi, payload)
        .then((_response) => {
          setToast({
            message: "Subscribed to newsletter successfully",
            type: "success",
          });
          setTimeout(() => {
            navigate("/home");
          }, 2000);
        })
        .catch((_error) => {
          //TODO: check if the user has subscribed already
          setToast({
            message: "Error occurred while subscribing to newsletter",
            type: "error",
          });
        });
    } catch (error) {
      setToast({
        message: "Error occurred while subscribing to newsletter",
        type: "error",
      });
    }
  };

  const handleOccurrenceChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const frequency = event.target.value;
    setFrequency(frequency as Frequency);
  };

  const handleGiveConsentChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, type } = event.target;

    if (type === "checkbox") {
      const checked = (event.target as HTMLInputElement).checked; // Type assertion for checkbox
      setForm((prevForm) => ({
        ...prevForm,
        [name]: checked,
      }));
    }
  };

  return {
    subscribeHandler,
    form,
    setForm,
    error,
    handleOccurrenceChange,
    handleGiveConsentChange,
    frequency,
    toast,
    setToast,
  };
};

export default useSubscribe;
