import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginApi } from "../../constants/apis";
import axiosInstance from "../../helpers/axiosInterceptors";
import { useAuth } from "../../providers/auth/authContext";
import extractAbpError from "../../utils/extractAbpError";
import SignInProps from "./interface";
import { useApp } from "../../providers/app/appContext";

const useSignIn = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { getReturnUrl } = useApp();
  const { setToken, setUser } = useAuth();
  const [form, setForm] = useState<SignInProps>({
    userNameOrEmailAddress: "",
    password: "",
    rememberClient: true,
  });

  const signIn = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (!form.userNameOrEmailAddress || !form.password) {
        setError("Please fill in all fields.");
        return;
      }

      setLoading(true);
      setError("");

      // Make API call to sign in
      const response = await axiosInstance.post(LoginApi, { ...form });

      if (response.status !== 200) {
        setError("Sign in failed. Please try again.");
        return;
      } else {
        console.log(response.data);
        await localStorage.setItem(
          "token",
          response?.data?.result?.accessToken
        );
        let userInfoObj = await axiosInstance.get(
          `/api/services/app/Person/GetCurrentPerson`
        );

        if (userInfoObj.status !== 200) {
          setError("Sign in failed. Please try again.");
          return;
        }

        await localStorage.setItem(
          "user",
          JSON.stringify(userInfoObj.data.result)
        );
        setUser(JSON.stringify(userInfoObj.data.result));
        setToken(response?.data?.result?.accessToken);
        const homeUrl = getReturnUrl()
          ? `/home/?returnUrl=${getReturnUrl()}`
          : "/home";
        navigate(homeUrl, { replace: true });
      }

      // Store token in local storage
    } catch (error) {
      // Handle sign in error
      const validationErrors = extractAbpError(error);
      setError(validationErrors ?? "Sign in failed. Please try again.");
    }

    setLoading(false);
  };

  return { loading, error, signIn, form, setForm };
};

export default useSignIn;
