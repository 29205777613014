import { useEffect } from "react";
import PageTitle from "../../components/pageTitle";

const DonationsPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PageTitle text="Donations Policy" />
      <div className="container mx-auto p-6 mt-10 mb-10">
        <p className="mb-4">
          Uginno.news is committed to upholding the values of integrity,
          respect, and human rights. We do not subscribe to any forms of
          terrorism, slavery, human trafficking, or any illegal activities that
          undermine these principles.
        </p>
        <p className="mb-4">We do not accept donations from:</p>
        <ul className="mb-4 list-disc list-inside">
          <li>
            Individuals or organizations that are known to promote or engage in
            terrorist activities.
          </li>
          <li>
            Any parties involved in human trafficking, slavery, or other forms
            of exploitation.
          </li>
          <li>
            Organizations that operate contrary to the values of justice,
            equality, and respect for human rights.
          </li>
          <li>
            Any entities whose funds are derived from illegal or unethical
            activities.
          </li>
        </ul>
        <p className="mb-4">
          By making a donation to Uginno.news, you affirm that your contribution
          aligns with our commitment to ethical standards and social
          responsibility. We reserve the right to refuse donations that do not
          meet these criteria.
        </p>
        <p className="mb-4">
          Thank you for supporting our mission to provide independent and
          trustworthy news coverage.
        </p>
      </div>
    </div>
  );
};

export default DonationsPolicyPage;
