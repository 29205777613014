import { useEffect, useState } from "react";
import { IContactUs } from "../../interfaces/contactUs";
import usePost from "../../network/usePost";
import { useNavigate } from "react-router-dom";

const useContactUs = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<IContactUs>({});
  const { post, error, data } = usePost<IContactUs>(
    "/api/services/app/ContactUsMessage/Create"
  );

  const [toast, setToast] = useState<{
    message: string;
    type: "info" | "success" | "error";
  } | null>(null);

  useEffect(() => {
    if (data) {
      setToast({ message: "Message sent successfully", type: "success" });
      setFormData({ message: "", name: "", email: "" });
    }

    if (error) {
      setToast({
        message: "Message failed to send. Please try again.",
        type: "error",
      });
      console.log(error);
    }
  }, [data]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!formData.name || !formData.email || !formData.message) {
        console.log("Please fill in all fields.");
        setToast({ message: "Please fill in all fields.", type: "error" });
        return;
      }
      post(formData);
    } catch (error) {
      console.log("Contact Us failed. Please try again.");
    }
  };
  return { formData, setFormData, post, error, handleSubmit, toast, setToast };
};

export default useContactUs;
