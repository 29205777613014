import { useEffect } from "react";
import PageTitle from "../../components/pageTitle";
import useNewsLetters from "./utils";
import ErrorLoadingEmptyPlaceholder from "../../components/errorLoadingEmptyPlaceholder";
import ICardComponent from "../../interfaces/cardComponent";
import GridCard from "../../components/gridCard";
import SectionTitle from "../../components/sectionTitle";

const NewslettersPage = () => {
  const {
    videos,
    stories,
    commentaryAndAnalysis,
    trends,
    podcasts,
    documentaries,
    error,
    loading,
    latestNewsLetter,
    features,
  } = useNewsLetters();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading || error || !latestNewsLetter) {
    return (
      <ErrorLoadingEmptyPlaceholder
        isLoading={loading}
        error={error ?? ""}
        isEmpty={!latestNewsLetter}
        emptyTitle="No newsletters found"
        emptyMessage="It seems there are no newsletters available at the moment."
        errorMessage="Sorry, we couldn't fetch the newsletters. Please try again later."
      />
    );
  }

  return (
    <div className="mx-4">
      <PageTitle text={latestNewsLetter?.title ?? "Newsletter"} />
      <div className="text-center">
        <h1 className="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1 uppercase">
          {latestNewsLetter?.synopsis}
        </h1>
      </div>
      <div className="container px-5 pt-12 pb-12 mx-auto">
        {stories?.length > 0 && (
          <>
            <SectionTitle text="News" />
            <div className="flex flex-wrap -m-4 mb-2">
              {stories.map((item: ICardComponent) => (
                <GridCard key={item.id} {...item} />
              ))}
            </div>
          </>
        )}

        {commentaryAndAnalysis?.length > 0 && (
          <>
            <SectionTitle text="Commentary And Analysis" />
            <div className="flex flex-wrap -m-4 mb-2">
              {commentaryAndAnalysis.map((item: ICardComponent) => (
                <GridCard key={item.id} {...item} />
              ))}
            </div>
          </>
        )}

        {features?.length > 0 && (
          <>
            <SectionTitle text="Features" />
            <div className="flex flex-wrap -m-4 mb-2">
              {features.map((item: ICardComponent) => (
                <GridCard key={item.id} {...item} />
              ))}
            </div>
          </>
        )}

        {trends?.length > 0 && (
          <>
            <SectionTitle text="Trends" />
            <div className="flex flex-wrap -m-4 mb-2">
              {trends.map((item: ICardComponent) => (
                <GridCard key={item.id} {...item} />
              ))}
            </div>
          </>
        )}

        {videos?.length > 0 && (
          <>
            <SectionTitle text="Videos" />
            <div className="flex flex-wrap -m-4 mb-2">
              {videos.map((item: ICardComponent) => (
                <GridCard key={item.id} {...item} />
              ))}
            </div>
          </>
        )}

        {documentaries?.length > 0 && (
          <>
            <SectionTitle text="Documentaries" />
            <div className="flex flex-wrap -m-4 mb-2">
              {documentaries.map((item: ICardComponent) => (
                <GridCard key={item.id} {...item} />
              ))}
            </div>
          </>
        )}

        {podcasts?.length > 0 && (
          <>
            <SectionTitle text="Podcasts" />
            <div className="flex flex-wrap -m-4 mb-2">
              {podcasts.map((item: ICardComponent) => (
                <GridCard key={item.id} {...item} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NewslettersPage;
