import ICardComponent from "../interfaces/cardComponent";

const getMainGridCardItem = (items: Array<ICardComponent>): ICardComponent => {
  const mainItem = items
    .map((item) => ({
      ...item,
      publishedDate: new Date(item.publishedDate ?? ""),
    }))
    .sort((a, b) => b.publishedDate.getTime() - a.publishedDate.getTime())
    .find((item) => item.isHeadline === true);

  if (mainItem) {
    return { ...mainItem };
  } else {
    const firstItem = items.find((item) => item.orderIndex === 0)!;
    return { ...firstItem };
  }
};

export default getMainGridCardItem;
