import { useEffect, useState } from "react";
import ICardComponent from "../../../interfaces/cardComponent";
import INews from "../../../interfaces/news";
import useGet from "../../../network/useGet";
import convertToNewsBulletin from "../../../utils/convertCardComponentToNewsBulletin";

const useGetByCategory = (category: string) => {
  const [stories, setStories] = useState<Array<ICardComponent>>([]);
  const [mainStory, setMainStory] = useState<ICardComponent | undefined>();
  const { data, error, loading, fetchData } = useGet<any>(
    `/api/services/app/News/GetAll?Category=${category}`,
    true
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      const modifiedNews = data?.items?.map((newsBulletin: INews) =>
        convertToNewsBulletin(newsBulletin)
      ) as Array<ICardComponent>;
      const headline = getHeadline(modifiedNews);
      if (headline && headline.id) {
        const remainingArticles = modifiedNews.filter(
          (article) => article.id !== headline.id
        );
        setMainStory(headline);
        setStories(remainingArticles);
      } else {
        setMainStory(undefined);
        setStories(modifiedNews);
      }
    }
  }, [data]);

  function findLatestHeadline(
    cards: ICardComponent[]
  ): ICardComponent | undefined {
    return cards
      .filter((card) => card.isHeadline && card.publishedDate)
      .reduce((latest, current) => {
        if (
          !latest ||
          (latest.publishedDate &&
            current.publishedDate &&
            current.publishedDate > latest.publishedDate)
        ) {
          return current;
        }
        return latest;
      }, undefined as ICardComponent | undefined);
  }

  function findLatestStory(
    cards: ICardComponent[]
  ): ICardComponent | undefined {
    return cards
      .filter((card) => card.publishedDate)
      .reduce((latest, current) => {
        if (
          !latest ||
          (latest.publishedDate &&
            current.publishedDate &&
            current.publishedDate > latest.publishedDate)
        ) {
          return current;
        }
        return latest;
      }, undefined as ICardComponent | undefined);
  }

  function getHeadline(cards: ICardComponent[]): ICardComponent | undefined {
    const headline = findLatestHeadline(cards);
    if (headline) {
      return headline;
    }
    return findLatestStory(cards);
  }

  return {
    stories,
    error,
    loading,
    mainStory,
  };
};

export default useGetByCategory;
