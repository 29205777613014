import useDetails from "./utils";
import PageTitle from "../../../components/pageTitle";
import { Link, useParams } from "react-router-dom";
import formatDate from "../../../utils/formatDate";
import authorImage from "../../../assets/images/author.jpg";

import YouTubeVideo from "../../../components/youTubeVideo";
import ErrorLoadingEmptyPlaceholder from "../../../components/errorLoadingEmptyPlaceholder";
import getCategoryString from "../../../utils/getCategoryString";

const PodCastDetails = () => {
  const { id } = useParams();
  const { podcast, error, loading, token } = useDetails(id ?? "");

  if (loading || error || !podcast) {
    return (
      <ErrorLoadingEmptyPlaceholder
        isLoading={loading}
        error={error ?? ""}
        isEmpty={!podcast}
        emptyTitle="No podcast details found"
      />
    );
  }

  return (
    <div>
      <PageTitle text={podcast?.title ?? "Documentary Details"} />
      <div className="container px-5 py-0 mx-auto flex flex-col">
        <div className="lg:w-4/6 mx-auto">
          <div className="rounded-lg h-full overflow-hidden">
            <YouTubeVideo videoId={podcast?.fileUrl!} height={80} />
          </div>

          <div className="flex items-center justify-between mt-4">
            <h2 className="text-base title-font font-medium text-indigo-500 uppercase">
              <span>{getCategoryString(podcast?.category ?? 0)} </span>
              {podcast?.externalLink && token && (
                <a
                  href={podcast?.externalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ( Podcast Link - External )
                </a>
              )}
            </h2>
            <div className="text-gray-500 text-base">
              Published on:&nbsp;
              {podcast?.publishedDate
                ? formatDate(podcast?.publishedDate)
                : "Date not available"}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row mt-10">
            {podcast?.description}
          </div>
          {token && (
            <div className="mt-8 p-6 bg-gray-100 rounded-lg mb-10">
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                About the Author
              </h3>
              <div className="flex items-center">
                <img
                  src={authorImage}
                  alt="Author"
                  className="w-16 h-16 rounded-full object-cover mr-4"
                />
                <div>
                  <h4 className="text-xl font-semibold text-gray-800">
                    {podcast?.author?.displayText}
                  </h4>
                  <p className="text-gray-600">{"Author Bio not available"}</p>
                  <p className="text-sm text-blue-600">
                    <Link to={`/podcastsByAuthor/${podcast?.author?.id}`}>
                      More by {podcast?.author?.displayText ?? "Unknown"}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PodCastDetails;
