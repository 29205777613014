import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ErrorMessage from "../../components/errorMessage";
import useRegister from "./utils";
import newspaperImage from "../../assets/images/newspaper.jpg";

const RegisterPage = () => {
  const { formData, handleSubmit, error, setFormData } = useRegister();

  return (
    <div className="min-h-screen flex items-center justify-center py-20">
      <div className="relative w-full max-w-5xl flex flex-col lg:flex-row">
        {/* Registration Form */}
        <motion.div
          className="bg-white p-8 rounded-lg shadow-lg w-full lg:w-1/2"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">
            Register
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name *
              </label>
              <input
                type="text"
                id="name"
                className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
                required
              />
            </div>

            <div>
              <label
                htmlFor="surname"
                className="block text-sm font-medium text-gray-700"
              >
                Surname *
              </label>
              <input
                type="text"
                id="surname"
                className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
                required
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email *
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e) =>
                  setFormData({ ...formData, emailAddress: e.target.value })
                }
                required
              />
            </div>

            <div>
              <label
                htmlFor="mobileNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Mobile Number *
              </label>
              <input
                type="text"
                id="mobileNumber"
                className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e) =>
                  setFormData({ ...formData, mobileNumber: e.target.value })
                }
                required
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password *
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                required
              />
            </div>

            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm Password *
              </label>
              <input
                type="password"
                id="confirmPassword"
                className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={formData.passwordConfirmation}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    passwordConfirmation: e.target.value,
                  })
                }
                required
              />
            </div>

            <div>
              <button
                type="submit"
                className="w-full bg-primaryColor text-white py-2 rounded-lg shadow-md hover:bg-accentColor transition duration-300"
              >
                Register
              </button>
              <p className="mt-4 text-gray-600 text-center">
                Already have an account?{" "}
                <Link
                  to={`/signIn`}
                  className="text-primaryColor hover:text-indigo-700 transition duration-300"
                >
                  Sign In
                </Link>
              </p>
            </div>

            <div className="flex items-center justify-center">
              <ErrorMessage message={error} />
            </div>
          </form>
        </motion.div>

        {/* Benefits Section */}
        <motion.div
          className="hidden lg:flex w-1/2 bg-gray-200 p-8 rounded-lg shadow-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          <div className="flex flex-col items-center justify-center space-y-6">
            <img
              src={newspaperImage}
              alt="Benefits"
              className="w-3/4 h-auto rounded-lg shadow-lg"
            />
            <h3 className="text-2xl font-semibold text-gray-800">
              Why Register?
            </h3>
            <p className="text-gray-600 text-center">
              Register to enjoy personalized content, manage your preferences,
              and receive updates about our latest features and offerings.
              Experience more by joining our community!
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default RegisterPage;
