import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import useSubscribe from "./utils";
import ErrorMessage from "../../components/errorMessage";
import newspaperImage from "../../assets/images/subscribe.jpg";
import Toast from "../../components/toast/toast";

const SubscribePage = () => {
  const {
    subscribeHandler,
    form,
    setForm,
    error,
    handleOccurrenceChange,
    handleGiveConsentChange,
    toast,
    setToast,
    frequency,
  } = useSubscribe();
  return (
    <div className="flex flex-col m-10 items-center justify-center min-h-screen">
      {toast && (
        <div className="fixed top-5 left-1/2 transform -translate-x-1/2 w-full max-w-md z-50">
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        </div>
      )}

      <div className="flex items-center justify-center min-h-screen">
        {/* Container for the Sign In Form and Additional Section */}
        <div className="relative w-full max-w-screen-lg flex flex-col lg:flex-row">
          {/* Sign In Form */}
          <div className="flex flex-col items-center justify-center w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg z-10 ">
            <motion.h2
              className="text-3xl font-bold text-center text-gray-800"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Subscribe
            </motion.h2>
            <form onSubmit={subscribeHandler} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  How often would you like to receive the newsletter? *
                </label>
                <select
                  name="preferredFrequency"
                  value={frequency ?? ""} // Handle null or undefined values
                  onChange={(e) => handleOccurrenceChange(e)}
                  className="mt-1 block w-full border p-2 rounded-md shadow-sm"
                  required
                >
                  <option value="" disabled>
                    Select occurrence
                  </option>
                  {["Daily", "Weekly", "Monthly"].map((occurrence) => (
                    <option key={occurrence} value={occurrence}>
                      {occurrence}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Consent to receive the newsletter *
                </label>
                <input
                  type="checkbox"
                  name="consentGiven"
                  checked={form?.consentGiven ?? false}
                  onChange={handleGiveConsentChange}
                  className="form-checkbox h-4 w-4 text-primaryColor transition duration-150 ease-in-out"
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 font-medium text-white bg-primaryColor rounded-md shadow-sm hover:bg-accentColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
                >
                  Subscribe
                </button>
              </div>
              <div className="flex items-center justify-center">
                <ErrorMessage message={error} />
              </div>
            </form>
          </div>

          {/* Additional Benefits Section */}
          <motion.div
            className="hidden lg:block w-1/2 h-full bg-gray-100 p-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex flex-col items-center justify-center h-full space-y-4">
              <img
                src={newspaperImage}
                alt="Benefits"
                className="w-3/4 h-auto rounded-lg shadow-lg"
              />
              <motion.h3
                className="text-xl font-semibold text-gray-800"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                Why Subscribe?
              </motion.h3>
              <motion.p
                className="text-gray-600 text-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                Stay Updated: Subscribing ensures you stay informed about the
                latest news, trends, and updates in your areas of interest.
                Exclusive Content: Many newsletters offer exclusive insights,
                expert opinions, or special offers that aren't available to
                non-subscribers. Personalized Content: Newsletters are often
                tailored based on your interests and preferences, so you get
                information that's relevant to you. Convenience: Rather than
                constantly checking websites or apps, newsletters deliver
                curated content straight to your inbox, saving you time and
                effort. Community Access: Subscribing can give you access to a
                community, event invitations, or feedback opportunities.
              </motion.p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SubscribePage;
