import { useEffect, useState } from "react";
import useGet from "../../../network/useGet";
import INews from "../../../interfaces/news";
import ICardComponent from "../../../interfaces/cardComponent";
import convertToNewsBulletin from "../../../utils/convertCardComponentToNewsBulletin";

const useDetails = (id: string) => {
  const { data, error } = useGet<INews>(`/api/services/app/News/Get?Id=${id}`);
  const [story, setStory] = useState<ICardComponent | undefined>();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      setStory(convertToNewsBulletin(data));
      console.log(data);
    }

    if (error) {
      console.log(error);
    }
  }, [data, error]);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    setToken(storedToken);
  }, []);

  return { data, error, story, token };
};

export default useDetails;
