import { useState, useEffect, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../button";
import logo from "../../assets/images/logo.jpeg";
import { IHeaderProps } from "./interface";
import { useAuth } from "../../providers/auth/authContext";
import RegisterUserProps from "../../pages/register/interface";
import axiosInstance from "../../helpers/axiosInterceptors";

const Header = (props: IHeaderProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const location = useLocation();
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const { token, logout } = useAuth();
  const [initials, setIntials] = useState<string>("");
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const toggleDrawer = () => setIsDrawerOpen((prev) => !prev);

  const getUserInitials = () => {
    if (user && user.firstName && user.lastName) {
      return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
    }
    return "NA"; // Fallback if user's name is not available
  };

  const toggleDropdown = (dropdown: string) => {
    setOpenDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest(".dropdown")) {
      setOpenDropdown(null);
    }
  }, []);

  const navigateToHome = () => {
    navigate("/home");
  };

  const getSubscribeLink = () => {
    if (token) {
      return "/subscribe";
    } else {
      return "/signIn?returnUrl=/subscribe";
    }
  };

  useEffect(() => {
    (async () => {
      const cachedUser = await localStorage.getItem("user");
      if (cachedUser != "null" && !user) {
        setUser(JSON.parse(cachedUser!));
      }
      if (cachedUser == "null") {
        let userInfoObj = await axiosInstance.get(
          `/api/services/app/Person/GetCurrentPerson`
        );

        if (userInfoObj.status !== 200) return;

        await localStorage.setItem(
          "user",
          JSON.stringify(userInfoObj.data.result)
        );
        setUser(userInfoObj.data.result);
        getUserInitials();
      }
    })();
  }, [user]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  useEffect(() => {
    setOpenDropdown(null);
  }, [location]);

  useEffect(() => {
    setIntials(getUserInitials());
  }, [user]);

  const isActive = (path: string) => location.pathname === path;

  return (
    <header className='body-font fixed top-0 left-0 w-full h-24 z-50 bg-white shadow-md'>
      <div className='container mx-auto flex flex-wrap p-5 items-center bg-white'>
        {/* Inner div for logo and button */}
        <div className='w-full md:w-auto flex justify-between items-center'>
          <Link to='/' className='flex title-font items-center mb-0'>
            <img
              src={logo}
              alt='Logo'
              style={{ width: '150px', objectFit: 'cover' }}
            />
          </Link>
          <button
            className='md:hidden ml-auto inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-0 md:mt-0'
            onClick={toggleDrawer}
          >
            {isDrawerOpen ? (
              <svg
                className='w-6 h-6'
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                viewBox='0 0 24 24'
              >
                <path d='M6 18L18 6M6 6l12 12' />
              </svg>
            ) : (
              <svg
                className='w-6 h-6'
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                viewBox='0 0 24 24'
              >
                <path d='M4 6h16M4 12h16M4 18h16' />
              </svg>
            )}
          </button>
        </div>
        <nav
          className={`md:ml-auto md:flex flex-wrap items-center text-base justify-center ${
            isDrawerOpen ? 'flex' : 'hidden'
          } flex-col md:flex-row md:justify-start`} // Ensure it's centered on mobile and left aligned on larger screens
        >
          <button
            className='hidden md:block ml-auto inline-flex items-center border-0 py-1 px-0 focus:outline-none rounded text-base mt-4 md:mt-0 group'
            onClick={navigateToHome}
          >
            <svg
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              className='w-5 h-5 group-hover:text-primaryColor'
              viewBox='0 0 24 24'
            >
              <path d='M4 6h16M4 12h16M4 18h16' />
            </svg>
          </button>

          {['home', 'news'].map((dropdown) => (
            <div key={dropdown} className='relative dropdown'>
              <button
                className={`py-2 px-4 md:py-0 md:px-4 flex items-center ${
                  isActive(`/${dropdown}`)
                    ? 'text-primaryColor font-bold'
                    : 'hover:text-primaryColor'
                }`}
                onClick={() => toggleDropdown(dropdown)}
              >
                {dropdown.charAt(0).toUpperCase() + dropdown.slice(1)}
                <svg
                  fill='none'
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  className={`w-4 h-4 ml-1 transform transition-transform ${
                    openDropdown === dropdown ? 'rotate-180' : ''
                  }`}
                  viewBox='0 0 24 24'
                >
                  <path d='M6 9l6 6 6-6' />
                </svg>
              </button>
              {openDropdown === dropdown && (
                <div className='absolute mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-lg z-10'>
                  {dropdown === 'home' && (
                    <>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to='/news'
                      >
                        News
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${12}/⁠Commentary & Analysis`}
                      >
                        ⁠Commentary & Analysis
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${10}/Features`}
                      >
                        Features
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to='/documentaries'
                      >
                        Documentaries
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${11}/Videos`}
                      >
                        Videos
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to='/podcasts'
                      >
                        Podcasts
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={getSubscribeLink()}
                      >
                        Subscribe
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to='/advertise'
                      >
                        Advertise
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to='/buyMeACoffee'
                      >
                        Donate
                      </Link>
                    </>
                  )}
                  {dropdown === 'news' && (
                    <>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${1}/Politics`}
                      >
                        Politics
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${2}/Economy`}
                      >
                        Economy
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${3}/Business`}
                      >
                        Business
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${4}/Technology`}
                      >
                        Technology
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${5}/Innovation`}
                      >
                        Innovation
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${6}/Entrepreneurship`}
                      >
                        Entrepreneurship
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${7}/Geo-strategy`}
                      >
                        Geo-strategy & Security
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${8}/Lifestyle`}
                      >
                        Lifestyle
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${9}/Culture`}
                      >
                        Culture
                      </Link>
                      <Link
                        className='block px-4 py-2 hover:bg-gray-100'
                        to={`/filteredStories/${10}/Trends`}
                      >
                        Trends
                      </Link>
                    </>
                  )}
                </div>
              )}
            </div>
          ))}

          <Link
            className='block px-4 py-2 hover:bg-gray-100'
            to={getSubscribeLink()}
          >
            Subscribe
          </Link>
          <Link className='block px-4 py-2 hover:bg-gray-100' to='/advertise'>
            Advertise
          </Link>
          {token ? (
            <div className='relative user-dropdown'>
              <button
                className='flex items-center space-x-2'
                onClick={() => setUserDropdownOpen((prev) => !prev)}
              >
                <div className='w-8 h-8 flex items-center justify-center bg-primaryColor rounded-full text-white font-bold'>
                  {initials}
                </div>
                <svg
                  fill='none'
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  className={`w-4 h-4 transform transition-transform ${
                    userDropdownOpen ? 'rotate-180' : ''
                  }`}
                  viewBox='0 0 24 24'
                >
                  <path d='M6 9l6 6 6-6' />
                </svg>
              </button>
              {userDropdownOpen && (
                <div className='absolute right-30 md:right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-lg z-10'>
                  <Link
                    className='block px-4 py-2 hover:bg-gray-100'
                    to='/profile'
                  >
                    Profile
                  </Link>
                  <button
                    className='block w-full text-left px-4 py-2 hover:bg-gray-100'
                    onClick={logout}
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Link to='/signIn'>
              <Button
                onClick={() => {}}
                text='Login'
                icon={
                  <svg
                    fill='none'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    className='w-4 h-4 ml-1'
                    viewBox='0 0 24 24'
                  >
                    <path d='M5 12h14M12 5l7 7-7 7' />
                  </svg>
                }
              />
            </Link>
          )}
        </nav>
      </div>
    </header>
  )
};

export default Header;
