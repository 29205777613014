import ICardComponent from "../interfaces/cardComponent";
import INews from "../interfaces/news";
import getCategoryString from "./getCategoryString";

const convertToGridCardItem = (newsItem: INews): ICardComponent => {
  return {
    id: newsItem?.id ?? "",
    title: newsItem?.title ?? "Title",
    description: newsItem?.synopsis ?? newsItem?.description ?? "Description",
    synopsis: newsItem?.synopsis ?? "",
    image:
      newsItem?.coverImageUrl ??
      "https://e3.365dm.com/21/07/1600x900/skynews-south-africa_5447279.jpg?20210714175423",
    category: getCategoryString(newsItem?.category ?? 0) ?? "News",
    createdDate: newsItem?.publishedDate,
    createdBy: newsItem?.author?.displayText ?? "Unknown",
    link: newsItem?.videoUrl ? "/documentaryDetails" : "/storydetails",
    linkText: "Read more",
    videoId: newsItem?.videoUrl ?? "",
    duration: newsItem?.duration,
    orderIndex: newsItem?.orderIndex ?? 0,
    externalLink: newsItem?.externalLink ?? "",
    publishedDate: new Date(newsItem?.publishedDate ?? ""),
    isHeadline: newsItem?.isHeadline,
  };
};

export default convertToGridCardItem;
