import { useEffect, useState } from "react";
import axiosInstance from "../../helpers/axiosInterceptors";
import RegisterUserProps from "../register/interface";
import useGet from "../../network/useGet";
import { useAuth } from "../../providers/auth/authContext";
const useProfile = (id: string) => {
  const { user } = useAuth();

  return { profile: user };
};
export default useProfile;
