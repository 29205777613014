import React from "react";
import { motion } from "framer-motion";
import newspaperImage from "../../assets/images/ad.jpg";

const AdvertisePage = () => {
  const [form, setForm] = React.useState({
    title: "",
    description: "",
    adType: "",
  });

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="relative w-1/2 max-w-screen-lg flex items-center justify-center lg:justify-between mx-auto">
        {/* Benefits Section */}
        <motion.div
          className="w-full w-3/4 flex flex-col items-center justify-center bg-gray-100 p-8 space-y-4 rounded-lg shadow-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <img
            src={newspaperImage}
            alt="Advertise Benefits"
            className="w-3/4 h-auto rounded-lg shadow-lg"
          />
          <motion.h3
            className="text-xl font-semibold text-gray-800"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            Why Advertise with Us?
          </motion.h3>
          <motion.p
            className="text-gray-600 text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            Reach a broad audience, increase brand visibility, and promote your
            products through our platform. Enjoy tailored advertising options
            for impactful campaigns.
            <br />
            Email:{" "}
            <a
              href="mailto:advertise@uggino.com"
              className="text-blue-600 hover:underline"
            >
              advertise@uginno.com
            </a>
            <br />
            Tel:{" "}
            <a
              href="tel:+27717645518"
              className="text-blue-600 hover:underline"
            >
              +27 717645518
            </a>
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
};

export default AdvertisePage;
