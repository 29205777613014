import { useEffect } from "react";
import PageTitle from "../../components/pageTitle";
import MainHeadlineCard from "../../components/mainHeadlineCard";
import GridCard from "../../components/gridCard";
import useDocumentaries from "./utils";
import ErrorLoadingEmptyPlaceholder from "../../components/errorLoadingEmptyPlaceholder";

const DocumentariesPage = () => {
  const { documentaries, loading, error, mainDocumentary } = useDocumentaries();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading || error || !documentaries?.length) {
    return (
      <ErrorLoadingEmptyPlaceholder
        isLoading={loading}
        error={error ?? ""}
        isEmpty={!documentaries?.length}
        emptyTitle="No documentaries found"
        emptyMessage="It seems there are no documentaries available at the moment."
        errorMessage="Sorry, we couldn't fetch the documentaries. Please try again later."
      />
    );
  }

  return (
    <div>
      <PageTitle text="Documentaries" />
      <section className="text-gray-600 body-font">
        <div className="container px-5 pt-12 pb-12 mx-auto">
          <MainHeadlineCard {...mainDocumentary} />
          <div className="mb-8"></div>
          <div className="flex flex-wrap -m-4">
            {documentaries.map((section) => (
              <GridCard key={section.id} {...section} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default DocumentariesPage;
