import { get } from "http";
import React from "react";

interface YouTubeVideoProps {
  videoId: string;
  height?: number;
}

const YouTubeVideo: React.FC<YouTubeVideoProps> = ({
  videoId,
  height = 60,
}) => {
  return (
    <div className="aspect-w-16 aspect-h-9">
      <iframe
        className={`w-full h-${height}`}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube Video"
      ></iframe>
    </div>
  );
};

export default YouTubeVideo;
