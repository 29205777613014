import { useEffect } from "react";
import PageTitle from "../../../components/pageTitle";
import useDocumentaryDetails from "./util";
import { Link, useParams } from "react-router-dom";
import formatDate from "../../../utils/formatDate";
import authorImage from "../../../assets/images/author.jpg";
import YouTubeVideo from "../../../components/youTubeVideo";
import ErrorLoadingEmptyPlaceholder from "../../../components/errorLoadingEmptyPlaceholder";
import getCategoryString from "../../../utils/getCategoryString";

const DocumentariesDetailsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  const {
    data: documentary,
    cardItem,
    loading,
    error,
  } = useDocumentaryDetails(id!);

  if (loading || error || !documentary) {
    return (
      <ErrorLoadingEmptyPlaceholder
        isLoading={loading}
        error={error ?? ""}
        isEmpty={!documentary}
        emptyTitle="No details found"
      />
    );
  }

  return (
    <div>
      <PageTitle text={documentary?.title ?? "Documentary Details"} />
      <div className="container px-5 py-0 mx-auto flex flex-col">
        <div className="lg:w-4/6 mx-auto">
          <div className="rounded-lg h-full overflow-hidden">
            <YouTubeVideo videoId={cardItem?.videoId!} height={80} />
          </div>
          <div className="flex items-center justify-between mt-4">
            <h2 className="text-base title-font font-medium text-indigo-500 uppercase">
              {getCategoryString(documentary?.category ?? 0)}
            </h2>
            <div className="text-gray-500 text-base">
              Published on:&nbsp;
              {formatDate(documentary?.publishedDate ?? "")}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row mt-10">
            {documentary?.description}
          </div>

          {/* Author Bio Section */}
          <div className="mt-8 p-6 bg-gray-100 rounded-lg mb-10">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              About the Author
            </h3>
            <div className="flex items-center">
              <img
                src={cardItem?.authorImage ?? authorImage}
                alt="Author"
                className="w-16 h-16 rounded-full object-cover mr-4"
              />
              <div>
                <h4 className="text-xl font-semibold text-gray-800">
                  {cardItem?.createdBy}
                </h4>
                <p className="text-gray-600">
                  {cardItem?.authorBio ??
                    "Boast a decade of experience in journalism, Zanele has published articles for top news companies ranging from News24, BBC, SABC and much more.."}
                </p>
                <p className="text-sm text-blue-600">
                  <Link
                    to={`/storiesByAuthor/cc83ab49-5637-4a75-0d37-08dcb783feda`}
                  >
                    More by {cardItem?.createdBy ?? "Unknown"}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentariesDetailsPage;
