enum NewsCategory {
  Unknown = 0,
  Politics = 1,
  Economy = 2,
  Business = 3,
  Technology = 4,
  Innovation = 5,
  Entrepreneurship = 6,
  GeoStrategy = 7,
  Lifestyle = 8,
  Culture = 9,
  Feature = 10,
  Videos = 11,
  Commentary_And_Analysis = 12,
  /*Health,
  Science,
  Entertainment,
  Sports,
  World,
  Education,
  Environment,
  Opinion,
  Arts,
  Travel,
  Crime,
  Weather,*/
}

export default NewsCategory;
